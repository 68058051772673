import React from "react";

import DataTable from "react-data-table-component";

import "../../../style/Infra/table.scss";
const CommonTable = (props) => {
  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    props.selectedData(selectedRows);
  };
  const customStyles = {
    table: {
      // font: "Outfit",
    },
    headRow: {
      style: {
        backgroundColor: "#253A71",
        color: "white",
        borderTopStyle: "solid",
        borderTopWidth: "0.1rem",
        borderTopColor: "#e6e6e6",
        font: "5px",
        minHeight: "40px",
      },
    },
  };

  return (
    <div className="commonTable">
      <DataTable
        pagination
        className={
          props?.data?.length === 1 && props?.removeSearchCheckbox
            ? "infraTableHideFirstAndSecondField"
            : props?.removeSearchCheckbox
            ? "infraTable"
            : ""
        }
        customStyles={customStyles}
        columns={props.columns} // pass columns array
        data={props.data} // table data
        // selectableRows={props.selectableRows} for checkbox
        selectableRows={false} //for checkbox
        onSelectedRowsChange={props.onSelectedRowsChange} //handle checkbox change
        responsive
        onRowClicked={props.onRowClicked}
        onSort={props.handleSort}
        onChangeRowsPerPage={props.handlePerRowsChange}
        sortServer
        paginationResetDefaultPage={props?.resetPagination}
        progresspending={props?.loading}
        onChangePage={props?.onChangePage}
        // paginationComponentOptions={paginationComponentOptions}
      />
    </div>
  );
};
export default CommonTable;
