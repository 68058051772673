import React, { useEffect, useState } from "react";

import { Spinner, Tab } from "react-bootstrap";

import Tabs from "react-bootstrap/Tabs";

import AddTag from "./addTag";
import axios from "../../api/axios";
import searchIcon from "../../Images/searchIcon.svg";
import tagIconDisabled from "../../Images/tag.svg";
import refreshIcon from "../../Images/refresh.svg";
import filterIcon from "../../Images/filterFilled.svg";
import tagIconEnabled from "../../Images/tagEnabled.svg";
import "../../style/Infra/accordionBody.scss";
import DownloadIcon from "../../Images/DownloadIcon.svg";

import {
  CaseInsensitiveSort,
  DownloadFile,
  ToastError,
  ToastSuccess,
} from "../../utils/utils";
import TagSearchModal from "../Infra/TagSearchModal/tagSearchModal";
import {
  downloadApiMessage,
  FileName,
  inventoryDropdownValues,
  toastIds,
} from "../../constants/constant";
import TableColumnSearch from "../common/TableSearchInput/tableSearch";
import TableWithNextToken from "../common/TableWithNextToken/table";
import Pagination from "../common/CustomPagination/Paginations";
import { API_URLS } from "../../constants/API_URLS";
import CommonTable from "../Infra/Table/table";
import CommonTooltip from "../Tooltip";

function AccordionBody(props) {
  const [open, setOpen] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [limit, setLimit] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tagKeyValuePairs, setTagKeyValuePairs] = useState([]);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);
  const [isPrevButtonDisabled, setIsPrevBtnDisabled] = useState(true);
  const [tokenIds, setTokenIds] = useState([]);
  const [addTagKeyValuePairs, setAddTagKeyValuePairs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const [addTag, setAddTag] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterCheckboxDisabled, setFilterCheckboxDisabled] = useState(false);
  const pushTokenItem = (item) => {
    const updatedTokenIds = [...tokenIds, item];
    setTokenIds(updatedTokenIds);
  };

  const popTokenItem = () => {
    if (tokenIds?.length === 0) {
      setIsPrevBtnDisabled(true);
      return;
    } else {
      setIsPrevBtnDisabled(false);
    }
    const updatedTokenIds = [...tokenIds.splice(-2)];
    setTokenIds(updatedTokenIds);
  };

  const onChangeFilter = (val, col, SearchObject) => {
    if (val) {
      let abc = tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let abcd = [SearchObject, ...abc];
      setTableData(abcd);
    } else {
      let abcd = [SearchObject, ...tableDataRaw];
      setTableData(abcd);
    }
  };

  const OnFilterClick = () => {
    let searchObj;
    if (
      props?.value == inventoryDropdownValues.VPC ||
      props?.value == inventoryDropdownValues.cf
    ) {
      searchObj = {};
      for (const item in columns) {
        searchObj[columns[item]?.id] = (
          <TableColumnSearch
            column={columns[item]?.id}
            onChange={(val, col) => {
              onChangeFilter(val, col, searchObj);
            }}
          ></TableColumnSearch>
        );
      }
      searchObj.awsRegion = (
        <TableColumnSearch
          column={"awsRegion"}
          onChange={(val, col) => {
            onChangeFilter(val, col, searchObj);
          }}
        ></TableColumnSearch>
      );
      searchObj.availabilityZone = (
        <TableColumnSearch
          column={"availabilityZone"}
          onChange={(val, col) => {
            onChangeFilter(val, col, searchObj);
          }}
        ></TableColumnSearch>
      );
      searchObj.arn = (
        <TableColumnSearch
          column={"arn"}
          onChange={(val, col) => {
            onChangeFilter(val, col, searchObj);
          }}
        ></TableColumnSearch>
      );
      searchObj.accountId = (
        <TableColumnSearch
          column={"accountId"}
          onChange={(val, col) => {
            onChangeFilter(val, col, searchObj);
          }}
        ></TableColumnSearch>
      );
    } else {
      searchObj = {};
      for (const item in columns) {
        searchObj[columns[item]?.id] = (
          <TableColumnSearch
            column={columns[item]?.id}
            onChange={(val, col) => {
              onChangeFilter(val, col, searchObj);
            }}
          ></TableColumnSearch>
        );
      }
    }
    if (currentPage == 1) {
      const onlyObjectsWithStrings = tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (isAdded) {
        setTableData(onlyObjectsWithStrings);
        setFilterCheckboxDisabled(false);
      } else {
        setTableData(() => {
          return [searchObj, ...onlyObjectsWithStrings];
        });
        setFilterCheckboxDisabled(true);
      }
    } else {
      if (isAdded) {
        setFilterCheckboxDisabled(false);
      } else {
        setTableData((preData) => {
          return [searchObj, ...preData];
        });
        setFilterCheckboxDisabled(true);
      }
    }
    setIsAdded((prevIsAdded) => !prevIsAdded);
    setResetPagination(true);
  };

  const getAllResourcesTableData = (isRefresh) => {
    setTableData([]);
    setLoading(true);
    let customHeaders = {
      nextToken: tokenIds.length > 0 ? tokenIds[tokenIds.length - 1] : null,
    };
    let postBody;
    if (tagKeyValuePairs?.length > 0) {
      postBody = {
        resourceType: [props?.data?.resourceType],
        regions:
          props?.selectedRegion?.length > 0 ? props?.selectedRegion : null,
        tagKey: tagKeyValuePairs[0]?.key
          ? tagKeyValuePairs?.map((data) => data?.key)
          : null,
        tagValue: tagKeyValuePairs[0]?.value
          ? tagKeyValuePairs?.map((data) => data?.value)
          : null,
        accountDetails:
          props?.selectedAccountIds?.length > 0
            ? props?.selectedAccountIds
            : null,
        accountID: null,
      };
    } else {
      postBody = {
        resourceType: [props?.data?.resourceType],
        regions:
          props?.selectedRegion?.length > 0 ? props?.selectedRegion : null,
        accountDetails:
          props?.selectedAccountIds?.length > 0
            ? props?.selectedAccountIds
            : null,
        accountID: null,
      };
    }
    axios
      .post(API_URLS?.infra?.inventory?.getTableDataAccordian, postBody, {
        headers: customHeaders,
      })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          if (isRefresh) {
            setIsPrevBtnDisabled(true);
          } else {
            setIsPrevBtnDisabled(!(tokenIds?.length > 0));
          }
          setIsNextBtnDisabled(!res?.data?.nextToken);
          setTotalCount(res?.data?.totalCount);
          pushTokenItem(res?.data?.nextToken);
          let result = res?.data?.data;
          const filteredData = result.map((item) => {
            const filteredItem = Object.keys(item)
              .filter((key) => item[key] !== null)
              .reduce((acc, key) => {
                acc[key] = item[key];
                return acc;
              }, {});
            return filteredItem;
          });
          let colData = Object.keys(filteredData[0]).map((innerData) => {
            let newItems = {
              name: innerData
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                }),
              selector: (row) => CommonTooltip(row[innerData]),
              id: innerData,
            };
            return newItems;
          });
          setColumns(colData);
          if (result?.length > 0) {
            setTableDataRaw(result);
            setTableData(result);
          } else {
            setTableData([]);
          }
        } else {
          ToastError(res?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.res?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTagsTableData = () => {
    if (props?.data?.resourceType === "Untagged Resources") {
      setTableData([]);
      setLoading(true);
      let customHeaders = {
        nextToken: tokenIds.length > 0 ? tokenIds[tokenIds.length - 1] : null,
      };
      const postBody = {
        resourceType: null,
        regions:
          props?.selectedRegion?.length > 0 ? props?.selectedRegion : null,
        tagKey: null,
        tagValue: null,
        accountID: null,
        accountDetails:
          props?.selectedAccountIds?.length > 0
            ? props?.selectedAccountIds
            : null,
      };
      axios
        .post(
          API_URLS?.infra?.inventory?.getUntagedTableDataAccordian,
          postBody,
          {
            headers: customHeaders,
          }
        )
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            setTotalCount(res?.data?.totalCount);
            setIsNextBtnDisabled(!res?.data?.nextToken);
            setIsPrevBtnDisabled(!(tokenIds?.length > 0));
            pushTokenItem(res?.data?.nextToken);
            let result = res?.data?.data;
            const filteredData = result.map((item) => {
              const filteredItem = Object.keys(item)
                .filter((key) => item[key] !== null)
                .reduce((acc, key) => {
                  acc[key] = item[key];
                  return acc;
                }, {});
              return filteredItem;
            });
            let colData = Object.keys(filteredData[0]).map((innerData) => {
              let newItems = {
                name: innerData
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  }),
                selector: (row) => CommonTooltip(row[innerData]),
                id: innerData,
              };
              return newItems;
            });
            setColumns(colData);
            if (result?.length > 0) {
              setTableDataRaw(result);
              setTableData(result);
            } else {
              setTableData([]);
            }
          } else {
            ToastError(res?.data?.statusMessage, toastIds.errorToastId);
          }
        })
        .catch((err) => {
          ToastError(err?.res?.data?.statusMessage, toastIds.errorToastId);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setTableData([]);
      setLoading(true);
      let customHeaders = {
        nextToken: tokenIds.length > 0 ? tokenIds[tokenIds.length - 1] : null,
      };
      const postBody = {
        key: props?.data?.resourceKey,
        value: props?.data?.resourceValue,
        accountID: null,
        accountDetails:
          props?.selectedAccountIds?.length > 0
            ? props?.selectedAccountIds
            : null,
        regions:
          props?.selectedRegion?.length > 0 ? props?.selectedRegion : null,
      };
      axios
        .post(API_URLS?.infra?.inventory?.getTagsTableDataAccordian, postBody, {
          headers: customHeaders,
        })
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            setTotalCount(res?.data?.totalCount);
            setIsNextBtnDisabled(!res?.data?.nextToken);
            setIsPrevBtnDisabled(!(tokenIds?.length > 0));
            pushTokenItem(res?.data?.nextToken);
            let result = res?.data?.data;
            //for generating dynamic columns
            const filteredData = result.map((item) => {
              const filteredItem = Object.keys(item)
                .filter((key) => item[key] !== null)
                .reduce((acc, key) => {
                  acc[key] = item[key];
                  return acc;
                }, {});
              return filteredItem;
            });
            let colData = Object.keys(filteredData[0]).map((innerData) => {
              let newItems = {
                name: innerData
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  }),
                selector: (row) => CommonTooltip(row[innerData]),
                id: innerData,
              };
              return newItems;
            });
            setColumns(colData);
            if (result?.length > 0) {
              setTableDataRaw(result);
              setTableData(result);
            } else {
              setTableData([]);
            }
          } else {
            ToastError(res?.data?.statusMessage, toastIds.errorToastId);
          }
        })
        .catch((err) => {
          ToastError(err?.res?.data?.statusMessage, toastIds.errorToastId);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onPreviousClick = () => {
    popTokenItem();
    if (props?.value == inventoryDropdownValues.tags) {
      getTagsTableData();
    } else if (props?.value == inventoryDropdownValues.allResources) {
      getAllResourcesTableData(false);
    }
  };

  const onAddTag = () => {
    if (addTagKeyValuePairs?.length > 0) {
      let tagPairs = {};
      for (const item in addTagKeyValuePairs) {
        tagPairs[addTagKeyValuePairs[item]?.key] =
          addTagKeyValuePairs[item]?.value;
      }
      const postBody = {
        resourceDetailsDTOList: selectedRows?.selectedRows?.map((data) => {
          return {
            resourceArn: data?.arn,
            accountId: data?.accountId,
            region: data?.awsRegion,
          };
        }),
        tagsPairs: tagPairs,
      };

      axios
        .post(API_URLS.infra.inventory.addTags, postBody)
        .then((response) => {
          let res = response?.data;
          if (res?.statusCode === 201) {
            ToastSuccess(res?.statusMessage, toastIds.successToastId);
          } else {
            ToastError(res?.statusMessage, toastIds.errorToastId);
          }
        })
        .catch((err) => {
          ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
        });
    }
  };

  const getVpcTableData = () => {
    let result = props?.vpcData?.relationShipDTOList;
    setTotalCount(props?.vpcData?.totalResourceCount);
    const filteredData = result.map((item) => {
      const filteredItem = Object.keys(item)
        .filter((key) => item[key] !== null)
        .reduce((acc, key) => {
          acc[key] = item[key];
          return acc;
        }, {});
      return filteredItem;
    });
    let colData = Object.keys(filteredData[0]).map((innerData) => {
      let newItems = {
        name: innerData
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          }),
        selector: (row) => row[innerData],
        id: innerData,
      };
      return newItems;
    });
    const addColumns = [
      {
        name: "Resource Code",
        selector: (row) => CommonTooltip(row.awsRegion),
      },
      {
        name: "Availability Zone",
        selector: (row) => CommonTooltip(row.availabilityZone),
      },
      {
        name: "Arn",
        selector: (row) => CommonTooltip(row.arn),
      },
      {
        name: "Account Id",
        selector: (row) => CommonTooltip(row.accountId),
      },
    ];
    colData.push(...addColumns);
    setColumns(colData);
    const newResult = result.map((data) => {
      let newItem = {
        ...data,
        awsRegion: props?.vpcData?.awsRegion,
        availabilityZone: props?.vpcData?.availabilityZone,
        arn: props?.vpcData?.arn,
        accountId: props?.vpcData?.accountId,
      };
      return newItem;
    });
    if (newResult?.length > 0) {
      setTableDataRaw(newResult);
      setTableData(newResult);
    } else {
      setTableData([]);
    }
  };

  const getCFTableData = () => {
    let result = props?.vpcData?.relationShipDTOList;
    setTotalCount(props?.vpcData?.totalResourceCount);
    const filteredData = result.map((item) => {
      const filteredItem = Object.keys(item)
        .filter((key) => item[key] !== null)
        .reduce((acc, key) => {
          acc[key] = item[key];
          return acc;
        }, {});
      return filteredItem;
    });
    let colData = Object.keys(filteredData[0]).map((innerData) => {
      let newItems = {
        name: innerData
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          }),
        selector: (row) => row[innerData],
        id: innerData,
      };
      return newItems;
    });
    const addColumns = [
      {
        name: "Resource Code",
        selector: (row) => CommonTooltip(row.awsRegion),
      },
      {
        name: "Availability Zone",
        selector: (row) => CommonTooltip(row.availabilityZone),
      },
      {
        name: "Arn",
        selector: (row) => CommonTooltip(row.arn),
      },
      {
        name: "Account Id",
        selector: (row) => CommonTooltip(row.accountId),
      },
    ];
    colData.push(...addColumns);
    setColumns(colData);
    const newResult = result.map((data) => {
      let newItem = {
        ...data,
        awsRegion: props?.vpcData?.awsRegion,
        availabilityZone: props?.vpcData?.availabilityZone,
        arn: props?.vpcData?.arn,
        accountId: props?.vpcData?.accountId,
      };
      return newItem;
    });
    if (newResult?.length > 0) {
      setTableDataRaw(newResult);
      setTableData(newResult);
    } else {
      setTableData([]);
    }
  };

  useEffect(() => {
    if (props?.value == inventoryDropdownValues.allResources) {
      getAllResourcesTableData(false);
    } else if (props?.value == inventoryDropdownValues.tags) {
      getTagsTableData();
    } else if (props?.value == inventoryDropdownValues.VPC) {
      getVpcTableData();
    } else {
      getCFTableData();
    }
    // }
  }, [
    props?.data?.resourceType,
    props.selectedAccountIds,
    props?.value,
    tagKeyValuePairs,
    limit,
  ]);

  useEffect(() => {
    onAddTag();
  }, [addTagKeyValuePairs, props.selectedAccountIds]);

  const jsonToCsv = (jsonData) => {
    if (!jsonData.length) return "";

    let csv = "";
    const headers = Object.keys(jsonData[0]);
    csv += headers.join(",") + "\n";

    jsonData.forEach((row) => {
      const data = headers.map((header) => `"${row[header] ?? ""}"`).join(",");
      csv += data + "\n";
    });

    return csv;
  };

  const downloadCsv = (csvData) => {
    if (!csvData) {
      console.error("No CSV data to download");
      return;
    }

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "UnTaggedResources.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onDownload = () => {
    if (!tableData || !tableData.length) {
      return;
    }

    const csvData = jsonToCsv(tableData); // Convert JSON to CSV
    downloadCsv(csvData); // Trigger download
  };

  const TableDisplay = () => {
    return (
      <div>
        <AddTag
          show={addTag}
          onHide={() => setAddTag(false)}
          getKeyValPairs={(data) => {
            setAddTagKeyValuePairs(data);
          }}
        />
        <TagSearchModal
          show={open}
          onHide={() => setOpen(false)}
          getKeyValPairs={(data) => {
            setTagKeyValuePairs(data);
          }}
        />
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-column">
            {selectedRows?.selectedRows?.length ? (
              <>
                <img
                  onClick={() => setAddTag(!addTag)}
                  className="accordionBody_allResourcesIcons"
                  src={tagIconEnabled}
                  alt="tagIconEnabled"
                ></img>
                <p
                  onClick={() => setAddTag(!addTag)}
                  className="accordionBody_allResourcesIcons_label"
                >
                  Add Tags
                </p>
              </>
            ) : (
              <>
                <img
                  className="accordionBody_allResourcesIcons"
                  src={tagIconDisabled}
                  alt="tagIconDisabled"
                ></img>
                <p className="accordionBody_allResourcesIcons_label">
                  Add Tags
                </p>
              </>
            )}
          </div>
          <div className="d-flex flex-row gap-3">
            <div onClick={() => setOpen(true)} className="d-flex flex-column">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="accordionBody_allResourcesIcons"
              ></img>
              <p className="accordionBody_allResourcesIcons_label">
                Tag Search
              </p>
            </div>
            <div className="d-flex flex-column">
              <img
                className="accordionBody_allResourcesIcons"
                src={filterIcon}
                alt="filterIcon"
                onClick={() => OnFilterClick()}
              ></img>
              <p className="accordionBody_allResourcesIcons_label">Filter</p>
            </div>
            <div
              onClick={() => {
                getAllResourcesTableData(true);
              }}
              className="d-flex flex-column"
            >
              <img
                className="accordionBody_allResourcesIcons"
                src={refreshIcon}
                alt="refreshIcon"
              ></img>
              <p className="accordionBody_allResourcesIcons_label">Refresh</p>
            </div>
          </div>
        </div>
        <div>
          <CommonTable
            removeSearchCheckbox={filterCheckboxDisabled}
            onChangePage={(data) => {
              setResetPagination(false);
              setCurrentPage(data);
            }}
            resetPagination={resetPagination}
            columns={columns}
            data={tableData}
            selectableRows={true}
            loading={loading}
            onSelectedRowsChange={(data) => setSelectedRows(data)}
          />
        </div>
      </div>
    );
  };

  const PageDisplay = () => {
    return (
      <div>
        <AddTag
          show={addTag}
          onHide={() => setAddTag(false)}
          getKeyValPairs={(data) => {
            setAddTagKeyValuePairs(data);
          }}
        />
        <div className="container-fluid accordionBody">
          <div className="d-flex justify-content-around shadow pt-4">
            {props.value == "Tags" ? (
              ""
            ) : (
              <div className="">
                <label className="titles">Name</label>
                <p className="subdata">{props?.vpcData?.arn?.split("/")[1]}</p>
              </div>
            )}

            <div className="">
              <label className="titles">Project</label>
              <p className="subdata">bvd</p>
            </div>
            {props.value == "Tags" ? (
              <div className="">
                <label className="titles">Tag Key</label>
                <p className="subdata">{props?.data?.resourceKey}</p>
              </div>
            ) : (
              <div className="">
                <label className="titles">Account</label>
                <p className="subdata">{props?.vpcData?.accountId}</p>
              </div>
            )}
            {props.value == "Tags" ? (
              <div className="">
                <label className="titles">Tag Value</label>
                <p className="subdata">{props?.data?.resourceValue}</p>
              </div>
            ) : (
              <div className="">
                <label className="titles">Region</label>
                <p className="subdata">{props?.vpcData?.awsRegion || "-"}</p>
              </div>
            )}
          </div>
          <div className="mt-4">
            <Tabs defaultActiveKey="Offers" className="mb-3">
              <Tab eventKey="Offers" title="Resources" className="tab"></Tab>
            </Tabs>
          </div>
          <div className="container_style">
            <div className="d-flex flex-wrap justify-content-between top-container mt-4">
              <div className="d-flex flex-column justify-content-between">
                {selectedRows?.selectedRows?.length ? (
                  <>
                    <img
                      onClick={() => setAddTag(!addTag)}
                      className="accordionBody_allResourcesIcons"
                      src={tagIconEnabled}
                      alt="tagIconEnabled"
                    ></img>
                    <p
                      onClick={() => setAddTag(!addTag)}
                      className="accordionBody_allResourcesIcons_label"
                    >
                      Add Tags
                    </p>
                  </>
                ) : (
                  <>
                    <img
                      className="accordionBody_allResourcesIcons"
                      src={tagIconDisabled}
                      alt="tagIconDisabled"
                    ></img>
                    <p className="accordionBody_allResourcesIcons_label">
                      Add Tags
                    </p>
                  </>
                )}
              </div>
              <div className="d-flex gap-4 ">
                <div className="d-flex flex-column">
                  <img
                    className="accordionBody_allResourcesIcons"
                    src={filterIcon}
                    alt="filterIcon"
                    onClick={() => OnFilterClick()}
                  ></img>
                  <p className="accordionBody_allResourcesIcons_label">
                    Filter
                  </p>
                </div>
                {props?.data?.resourceType === "Untagged Resources" && (
                  <div className="">
                    <div
                      className={`headerBoxIcon ${
                        tableData.length === 0
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                      onClick={() => {
                        if (tableData.length > 0) {
                          onDownload();
                        }
                      }}
                    >
                      <img
                        src={DownloadIcon}
                        alt="download"
                        className={
                          tableData.length === 0 ? "pointer-events-none" : ""
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              <CommonTable
                removeSearchCheckbox={filterCheckboxDisabled}
                onChangePage={(data) => {
                  setResetPagination(false);
                  setCurrentPage(data);
                }}
                resetPagination={resetPagination}
                columns={columns}
                data={tableData}
                selectableRows={true}
                loading={loading}
                onSelectedRowsChange={(data) => setSelectedRows(data)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <>{props.value == "All Resources" ? TableDisplay() : PageDisplay()}</>;
}
export default AccordionBody;
