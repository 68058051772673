import { useReducer } from "react";
import React, { useEffect } from "react";

import { FaPlay, FaStop } from "react-icons/fa";

import axios from "../../../../api/axios";
import refreshIcon from "../../../../Images/refresh.svg";
import filterIcon from "../../../../Images/filterFilled.svg";

import "../../../../style/Infra/NoteBookInstance.scss";
import TableColumnSearch from "../../../../components/common/TableSearchInput/tableSearch";
import { ToastError, ToastSuccess } from "../../../../utils/utils";
import OperationModal from "../../../../components/Infra/OperationModal";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import CommonTable from "../../../../components/Infra/Table/table";
import { API_URLS } from "../../../../constants/API_URLS";
import { toastIds } from "../../../../constants/constant";

const initialState = {
  tableData: [],
  openStartModal: false,
  openStopModal: false,
  loading: false,
  selectedRowsTable: undefined,
  selectedRows: undefined,
  tableDataRaw: undefined,
  resetPagination: false,
  isAdded: false,
  currentPage: 1,
  filterCheckboxDisabled: false,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function NoteBookInstance() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const columns = [
    {
      name: "Notebook Instance ID",
      selector: (row) => row.notebookInstanceId,
    },
    {
      name: "Status",
      selector: (row) => row.updatedStatus,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Instance Type",
      selector: (row) => row.instanceType,
    },
    {
      name: "Volume Size",
      selector: (row) => row.volumeSize,
    },
    {
      name: "Creation Time",
      selector: (row) => row.creationTime,
    },
    {
      name: "Account",
      selector: (row) => row.account,
    },
    {
      name: "Region",
      selector: (row) => row.regionName,
    },
  ];

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = state.tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setData({ tableData: mergedData });
    } else {
      const result = state.tableDataRaw.map((data, index) => {
        let newItem = {
          notebookInstanceId: data.notebookInstanceId,
          updatedStatus: data.updatedStatus,
          name: data.name,
          instanceType: data.instanceType,
          volumeSize: data.volumeSize,
          creationTime: data.creationTime,
          account: data.account,
          regionName: data.regionName,
          id: index,
        };
        return newItem;
      });
      let mergedData = [SearchObject, ...result];
      setData({ tableData: mergedData });
    }
  };

  const SearchObject = {
    notebookInstanceId: (
      <TableColumnSearch
        column={"notebookInstanceId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    updatedStatus: (
      <TableColumnSearch
        column={"updatedStatus"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    name: (
      <TableColumnSearch
        column={"name"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    instanceType: (
      <TableColumnSearch
        column={"instanceType"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    volumeSize: (
      <TableColumnSearch
        column={"volumeSize"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    creationTime: (
      <TableColumnSearch
        column={"creationTime"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    account: (
      <TableColumnSearch
        column={"account"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionName: (
      <TableColumnSearch
        column={"regionName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };

  const OnFilterClick = () => {
    if (state.currentPage == 1) {
      const onlyObjectsWithStrings = state.tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (state.isAdded) {
        setData({
          tableData: onlyObjectsWithStrings,
          filterCheckboxDisabled: false,
        });
      } else {
        setData({
          tableData: [SearchObject, ...onlyObjectsWithStrings],
          filterCheckboxDisabled: true,
        });
      }
    } else {
      if (state.isAdded) {
        setData({ filterCheckboxDisabled: false });
      } else {
        setData({
          tableData: [SearchObject, ...state.tableData],
          filterCheckboxDisabled: true,
        });
      }
    }
    setData({ isAdded: !state.isAdded, resetPagination: true });
  };

  const getnotebookData = () => {
    setData({
      loading: true,
    });

    axios
      .get(API_URLS.infra.compute.nootbook.notebookList)
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          // setTableData(result?.data[0]);
          setData({ tableDataRaw: result?.data[0] });
          let tableData = result?.data[0]?.map((data, index) => {
            let temp = {
              notebookInstanceId: data.notebookInstanceId,
              updatedStatus: data.updatedStatus,
              name: data.name,
              instanceType: data.instanceType,
              volumeSize: data.volumeSize,
              creationTime: data.creationTime,
              account: data.account,
              regionName: data.regionName,
              id: index,
            };
            return temp;
          });
          setData({ tableData: tableData });
        } else {
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };

  const OnNotebookOperation = (data) => {
    axios
      .post(API_URLS.infra?.compute?.nootbook?.notebookOperation, data)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          ToastSuccess(response?.data?.statusMessage, toastIds.successToastId);
          getnotebookData();
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      );
  };

  useEffect(() => {
    getnotebookData();
  }, []);

  useEffect(() => {
    const selectedNotebookIds = state.selectedRowsTable?.selectedRows?.map(
      (data) => {
        return data?.id;
      }
    );
    const selectedRowRaw = state.tableDataRaw?.filter((data, index) =>
      selectedNotebookIds.includes(index)
    );
    setData({ selectedRows: selectedRowRaw });
  }, [state.selectedRowsTable]);

  return (
    <>
      <OperationModal
        show={state.openStartModal}
        heading={"Start Notebook Instance"}
        subHeading={
          "Are you sure you want to start the below Notebook Instance?"
        }
        type={"Notebook"}
        operation={"start"}
        onHide={() => setData({ openStartModal: false })}
        selectedRows={state.selectedRows}
        onStart={(data) => OnNotebookOperation(data)}
      />

      <OperationModal
        show={state.openStopModal}
        heading={"Stop Notebook Instance"}
        subHeading={
          "Are you sure you want to stop the below Notebook Instance?"
        }
        type={"Notebook"}
        operation={"stop"}
        onHide={() => setData({ openStopModal: false })}
        selectedRows={state.selectedRows}
        onStop={(data) => OnNotebookOperation(data)}
      />
      <div className="notebook mx-4  mt-5">
        <h5 className=" text-start ms-1">AWS Notebook Instances</h5>
        <div className=" border rounded  border border-0 mt-3  mx-2">
          {/* <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap">
              {state.selectedRowsTable?.selectedCount == 1 &&
              state.selectedRowsTable?.selectedRows[0]?.updatedStatus ==
                "Stopped" ? (
                <>
                  <TableButton
                    icon={
                      <FaPlay
                        className="manage-icon"
                        onClick={() =>
                          setData({ openStartModal: !state.openStartModal })
                        }
                        color="black"
                      ></FaPlay>
                    }
                    title={"Start"}
                  />
                </>
              ) : (
                <>
                  <TableButton
                    icon={<FaPlay className="manage-icon"></FaPlay>}
                    title={"Start"}
                  />
                </>
              )}
              {state.selectedRowsTable?.selectedCount == 1 &&
              state.selectedRowsTable?.selectedRows[0]?.updatedStatus !=
                "Stopped" ? (
                <>
                  <TableButton
                    icon={
                      <FaStop
                        className="manage-icon"
                        onClick={() =>
                          setData({ openStopModal: !state.openStopModal })
                        }
                        color="black"
                      ></FaStop>
                    }
                    title={"Stop"}
                  />
                </>
              ) : (
                <>
                  <TableButton
                    icon={<FaStop className="manage-icon"></FaStop>}
                    title={"Stop"}
                  />
                </>
              )}
            </div>
            <div className="d-flex flex-wrap">
              {state.tableData.length > 0 && (
                <TableButton
                  icon={
                    <img
                      className="notebook notebook_tableIcons"
                      src={filterIcon}
                      alt="filterIcon"
                      onClick={() => OnFilterClick()}
                    ></img>
                  }
                  title={"Filter"}
                />
              )}
              <TableButton
                icon={
                  <img
                    className="notebook notebook_tableIcons"
                    src={refreshIcon}
                    alt="refreshIcon"
                    onClick={() => {
                      getnotebookData();
                    }}
                  ></img>
                }
                title={"Refresh"}
              />
            </div>
          </div> */}

          <CommonTable
            removeSearchCheckbox={state.filterCheckboxDisabled}
            onChangePage={(data) => {
              setData({ resetPagination: false, currentPage: data });
            }}
            resetPagination={state.resetPagination}
            columns={columns}
            onSelectedRowsChange={(data) =>
              setData({ selectedRowsTable: data })
            }
            data={state.tableData}
            selectableRows={true}
            loading={state.loading}
          />
        </div>
      </div>
    </>
  );
}
