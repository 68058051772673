import CommonTooltip from "../components/Tooltip";
import Colors from "./Colors";
import { ROUTE_PATH } from "./Routes";

export const CreateTicketInitialValues = {
  name: "",
  description: "",
  category_id: "",
  priority_id: "",
  project_id: "",
  hr_ticket_type_id: "",
  cloud_service_id: "",
  is_new_cloud_service: false,
  cloud_data: {},
  start_date: "",
  end_date: "",
  notify_client: false,
  assign_to: "",
  tags: [],
  observers: [],
};

export const inventoryDoughntdata = {
  labels: ["Asia Pacific (Mumbai)", "Global", "US East"],
  datasets: [
    {
      label: "",
      data: [12, 12, 25],
      backgroundColor: ["blue", "cyan", "yellow"],
      borderWidth: 1,
      hoverOffset: 4,
    },
  ],
};

export const operatingSystemColor = [
  "#2ca02c",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
  "#2ca02c",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
];

export const serverDonutColors = ["#237804", "red", "#fa8c16"];

export const kpiDashboardTabs = [
  // "KPI Tracker",
  // "Set KPI Goals",
  "Metrics Summary",
  // "EC2",
  // "EBS",
  // "S3",
  // "RDS",
  // "Other Graviton",
  // "Commit Optimization",
  // "About",
];

export const graphColors = [
  "#0053BC",
  "#FE42C9",
  "#FFA96A",
  "#CD8EFF",
  "#5AF3AA",
  "#78C8FF",
  "#FF7F00",
  "#4A90E2",
  "#FF6347",
  "#8A2BE2",
  "#00CED1",
];

export const itsmDynamicColor = ["#E1F0FF", "#FFEECF", "#DBDCFF", "#FFE2E5"];

export const localStorageHeaders = {
  serverDetails: "serverDetails",
  selectedServerGroup: "selectedServerGroup",
  SelectedValueSelect: "SelectedValueSelect",
  VpcData: "VpcData",
  selectedRowWorkspace: "selectedRowWorkspace",
  token: "token",
  userEmail: "userEmail",
  userName: "userName",
  optimizeActionDetails: "optimizeActionDetails",
};

export const inventoryDropdownValues = {
  allResources: "All Resources",
  tags: "Tags",
  VPC: "VPC",
  cf: "Cloud Formation",
  InActiveResources: "In-Active Resources",
};

export const serviceType = "EC2";
export const status = "inActive";

export const BillingNCTableCols = [
  {
    name: "Name",
    selector: (row) => CommonTooltip(row.name),
  },
  {
    name: "Cost ($)",
    selector: (row) => row.cost,
  },
];

export const BillingCTTableCols = [
  {
    name: "Tags",
    selector: (row) => CommonTooltip(row.tags),
  },
  {
    name: "Cost ($)",
    selector: (row) => row.cost,
  },
];

export const ProjectBillingTableCols = [
  {
    name: "Name",
    selector: (row) => {
      return row.appName || row.serviceName || row.resourceId;
    },
  },
  {
    name: "Cost ($)",
    selector: (row) => row.total_cost,
  },
];

export const Pie_Chart_Data_Header = [
  { label: "Role Arn", key: "role_Arn" },
  { label: "Account Name", key: "name" },
  { label: "ID", key: "id" },
  { label: "Cost", key: "cost" },
];
export const Names_Cost_Header = [
  { label: "Name", key: "name" },
  { label: "Cost", key: "cost" },
];

export const Tags_Cost_Header = [
  { label: "Tags", key: "tags" },
  { label: "Cost", key: "cost" },
];

// export const rootAccount = "arn:aws:iam::978645639397:role/BVD_Role_Demo";
export const rootAccount = "arn:aws:iam::533267253811:user/AFL-Breakglass-User";
export const rootAccount_1 = "arn:aws:iam::243916693733:role/ACCPL-CLMRole";

export const viewingPeriodDropDownData = [
  {
    key: "Current month",
    value: "Current month",
  },
  {
    key: "Last 3 months",
    value: "Last 3 months",
  },
  {
    key: "Last 6 months",
    value: "Last 6 months",
  },
  {
    key: "Last 1 Year",
    value: "Last 1 Year",
  },
];

export const InActiveTableCols = [
  {
    name: "Resource Id",
    selector: (row) => row.ResourceId,
  },
  {
    name: "Resource Type",
    selector: (row) => row.ResourceType,
  },
  {
    name: "Username",
    selector: (row) => row.Username,
  },
];

export const Actions = [
  { value: "Option 1" },
  { value: "Option 2" },
  { value: "Option 3" },
];

export const providerText = "AWS";
export const regionText = "ap-south-1";

export const ResourceBillingTableCols = [
  {
    name: "Resource Id",
    selector: (row) => CommonTooltip(row.line_item_resource_id),
  },
  {
    name: "Service Name",
    selector: (row) => CommonTooltip(row.product_servicename),
  },
  {
    name: "Item Cost",
    selector: (row) => row.line_item_unblended_cost,
  },
];

export const Resource_Service_Data_Header = [
  { label: "Resource Id", key: "line_item_resource_id" },
  { label: "Service Name", key: "product_servicename" },
  { label: "Item Cost", key: "line_item_unblended_cost" },
];

export const serverGroupLimit = 6;

export const regex = {
  imageNameRegex: /^[a-zA-Z0-9\s()[\]./'@_-]{3,108}$/,
  awsAccountNumberRegex: /^[0-9]{12}$/,
  twoDecimalsOnly: /^\d+(\.\d{2})?$/,
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  invalidBudgetName: /^[a-zA-Z0-9_#\- ]+$/,
  specialCharSpaceAndNumberValidation: /^[^0-9\s!@#$%^&*(),.?":{}|<>]+$/,
  onlyNumbersAndLetters: /^[^\s!@#$%^&*(),.?":{}|<>]+$/,
};

export const ErrorMessage = {
  tokenExpired: "Your session has expired. Please log in again.",
  validAccountId: "Please enter valid account id",
  invalidVolumeCreateImageName:
    "Image name must be between 3-108 characters, can contain spaces and the following characters - ()[]./-'@_",
  invalidCredentialsLogin:
    "Invalid credentials. Please attempt the login again later.",
  accountCost: "Error in fetching account cost",
  serviceCost: "Error in fetching service cost",
  getYearInsights: "Error in fetching year insights",
  getServiceCost: "Error in fetching service cost",
};

export const dummyInstanceSize = [
  { key: "t2.micro", value: "t2.micro" },
  { key: "t2.small", value: "t2.small" },
  { key: "t2.medium", value: "t2.medium" },
  { key: "t2.large", value: "t2.large" },
  { key: "t3.micro", value: "t3.micro" },
  { key: "t3.small  ", value: "t3.small " },
  { key: "t3.medium", value: "t3.medium" },
  { key: "t3.large", value: "t3.large" },
  { key: "m5.xlarge", value: "m5.xlarge" },
];

export const colours = [
  Colors.CORALRED,
  Colors.TRANSPARENTSKYBLUE,
  Colors.HEADERBORDER,
  Colors.LIMEGREEN,
  Colors.CORNFLOWERBLUE,
  Colors.HOTPINK,
  Colors.MEDIUMORCHID,
];

export const monitoringDropdownOptions = [
  {
    key: "1 Hour",
    value: "1hrs",
  },
  {
    key: "3 Hours",
    value: "3hrs",
  },
  {
    key: "5 Hours",
    value: "5hrs",
  },
  {
    key: "8 Hours",
    value: "8hrs",
  },
  {
    key: "12 Hours",
    value: "12hrs",
  },
  {
    key: "24 Hours",
    value: "24hrs",
  },
  {
    key: "1 Week",
    value: "1w",
  },
  {
    key: "2 Week",
    value: "2w",
  },
  {
    key: "4 Week",
    value: "4w",
  },
];

export const kpiDashboardContants = {
  MoM_gp2_to_gp3_Potential_Savings_by_Group_by:
    "MoM_gp2_to_gp3_Potential_Savings_by_Group_by",
  MoM_gp3_Coverage_by_Spend_Volume_Type_and_Group_by:
    "MoM_gp3_Coverage_by_Spend_Volume_Type_and_Group_by",
  MoM_EBS_Coverage_by_Spend_and_Volume_Type:
    "MoM_EBS_Coverage_by_Spend_and_Volume_Type",
  EBS_Volume_Lookup_Chart: "EBS_Volume_Lookup_Chart",
  MoM_EBS_Coverage_by_Unit_Cost_and_Volume_Type:
    "MoM_EBS_Coverage_by_Unit_Cost_and_Volume_Type",
  Potential_Savings_Graviton_vs_AMD_vs_Intel:
    "Potential_Savings-Graviton_vs._AMD_vs._Intel",
  Compute_Spend_by_Processor: "Compute_Spend_by_Processor",
  Estimated_Current_Cost_Efficiencies_for_Graviton_and_AMD:
    "Estimated_Current_Cost_Efficiencies_for_Graviton_and_AMD",
  Top_Opportunity_by_Instance_Family: "Top_Opportunity_by_Instance_Family",
  Metrices_summary: "Metrices_summary",
  Previous_Month_EBS_Snapshot_Size_by_Start_Date_Excludes_first_billed_date_of_a_Payer_Account:
    "Previous_Month_EBS_Snapshot_Size_by_Start_Date_Excludes_first_billed_date_of_a_Payer_Account",
  Previous_Month_gp_Spend_Coverage: "Previous_Month_gp_Spend_Coverage",
  Previous_EBS_Spend_by_Volume_Type: "Previous_EBS_Spend_by_Volume_Type",
  Coverage_by_Processor_for_Amazon_Relational_Database_Service:
    "Coverage_by_Processor_for_Amazon_Relational_Database_Service",
  Potential_Savings_from_migrating_to_Graviton_Based_on_Billing_Period:
    "Potential_Savings_from_migrating_to_Graviton_Based_on_Billing_Period",
  Coverage_by_Database_Engines_for_Amazon_Relational_Database_Service:
    "Coverage_by_Database_Engines_for_Amazon_Relational_Database_Service",
  Top_10_Accounts_Spend_for_Amazon_RDS_running_on_Graviton_Processors:
    "Top_10_Accounts_Spend_for_Amazon_RDS_running_on_Graviton_Processors",
  Top_10_Accounts_Spend_for_Amazon_RDS_running_on_Other_Intel_Processors:
    "Top_10_Accounts_Spend_for_Amazon_RDS_running_on_Other_Intel_Processors",
  S3_Unit_Cost: "S3_Storage_Unit_Cost",
  S3_Storage_Cost_by_Class: "S3_Storage_Cost_by_Class",
  Previous_Month_Potential_Savings_Graviton_vs_Other_Intel:
    "Previous_Month_Potential_Savings-Graviton_vs._Other_Intel",
};

export const commonConstants = {
  stackGraph: "stackGraph",
  AWS: "AWS",
  joinedGraph: "joinedGraph",
  billing_period: "billing_period",
  current_unit_cost: "current_unit_cost",
  create: "create",
  EC2: "EC2",
  RDS: "RDS",
  EBS: "EBS",
  S3: "S3",
  Other_Gravition: "Other",
  Top_Opportunity_by_Instance_Family: "Top_Opportunity_by_Instance_Family",
  Previous_Month_Process_Trend_by_Spend:
    "Previous_Month_Process_Trend_by_Spend",
  rightSizing: "Right Sizing",
  manageIdle: "Manage Idle",
  true: "true",
  time: "Time",
  cost: "Cost",
  ACC: "ACC",
  managed: "managed",
  modify: "modify",
  delete: "delete",
  start: "start",
  deregister: "deregister",
  stop: "stop",
  tags: "tags",
  reboot: "reboot",
  remove: "remove",
  rename: "rename",
  copy: "copy",
  regions: "regions",
  share: "share",
  implabel: "How could time to diagnosis be improved ?",
  soplabel: "SOP's Followed",
  correctlabel: "Corrective Action",
  cardTitle: "Are you sure?",
  cardText: "You won't be able to revert this!",
  createSnap: "create snapshot",
  scan: "scan",
  patch: "patch",
  createImage: "create image",
  resize: "resize instance",
  manageTag: "manage tags",
  cloudData: "AWS",
};

export const dateConstants = {
  fullDateWithTime: "DD-MMMM-YYYY hh:mm:ss",
};
export const modifyVolumeDropdownOptions = [
  {
    key: "standard",
    value: "standard",
  },
  {
    key: "io1",
    value: "io1",
  },
  {
    key: "io2",
    value: "io2",
  },
  {
    key: "gp2",
    value: "gp2",
  },
  {
    key: "sc1",
    value: "sc1",
  },
  {
    key: "st1",
    value: "st1",
  },
  {
    key: "gp3",
    value: "gp3",
  },
];

/* MASTERS API STARTS */
export const API_MASTERS_COUNT_ENDPOINT = "/masters/count";

export const API_GET_MASTER_CUSTOMER_ENDPOINT = "/masters/customers";
export const API_MASTER_GET_PERMISSION_ENDPOINT = "/masters/permissions";
export const API_MASTER_GET_ROLE_ENDPOINT = "/masters/roles";
export const API_GET_MASTER_PROJECT_ENDPOINT = "/masters/projects";

export const API_MASTER_CUSTOMER_LIST_ENDPOINT = "/masters/customers/list";
export const API_MASTER_PROJECT_LIST_ENDPOINT = "/masters/projects/list";
export const API_MASTER_ROLE_LIST_ENDPOINT = "/masters/roles/list";
export const API_MASTER_PRIORITY_LIST_ENDPOINT = "/masters/priorities/list";
export const API_MASTER_CATEGORIES_LIST_ENDPOINT = "/masters/categories/list";
export const API_MASTER_STATUS_LIST_ENDPOINT = "/masters/status/list";
export const API_MASTER_HRTICKETS_LIST_ENDPOINT = "/masters/hr-tickets/list";
export const API_MASTER_FIELDS_LIST_ENDPOINT = "/masters/fields/list";
export const API_MASTER_CLOUD_PROVIDER_LIST_ENDPOINT = "/masters/clouds/list";
export const API_MASTER_CLOUD_SERVICES_LIST_ENDPOINT =
  "/masters/cloud-services/list";
export const API_MASTER_CLOUD_SERVICE_FIELDS_LIST_ENDPOINT =
  "/masters/cloud-service-fields/list";
export const API_MASTER_PERMISSIONS_LIST_ENDPOINT = "/masters/permissions/list";

export const API_MASTER_CUSTOMER_DELETE_ENDPOINT = "/masters/customers/delete";
export const API_MASTER_PROJECT_DELETE_ENDPOINT = "/masters/projects/delete";
export const API_MASTER_ROLE_DELETE_ENDPOINT = "/masters/roles/delete";
export const API_MASTER_PRIORITY_DELETE_ENDPOINT = "/masters/priorities/delete";
export const API_MASTER_CATEGORIES_DELETE_ENDPOINT =
  "/masters/categories/delete";
export const API_MASTER_STATUS_DELETE_ENDPOINT = "/masters/status/delete";
export const API_MASTER_HRTICKETS_DELETE_ENDPOINT =
  "/masters/hr-tickets/delete";
export const API_MASTER_FIELDS_DELETE_ENDPOINT = "/masters/fields/delete";
export const API_MASTER_CLOUD_PROVIDER_DELETE_ENDPOINT =
  "/masters/clouds/delete";
export const API_MASTER_CLOUD_SERVICES_DELETE_ENDPOINT =
  "/masters/cloud-services/delete";
export const API_MASTER_CLOUD_SERVICE_FIELDS_DELETE_ENDPOINT =
  "/masters/cloud-service-fields/delete";

export const API_MASTER_CUSTOMER_CREATE_ENDPOINT = "/masters/customers/create";
export const API_MASTER_PROJECT_CREATE_ENDPOINT = "/masters/projects/create";
export const API_MASTER_ROLE_CREATE_ENDPOINT = "/masters/roles/create";
export const API_MASTER_PRIORITY_CREATE_ENDPOINT = "/masters/priorities/create";
export const API_MASTER_CATEGORIES_CREATE_ENDPOINT =
  "/masters/categories/create";
export const API_MASTER_STATUS_CREATE_ENDPOINT = "/masters/status/create";
export const API_MASTER_HRTICKETS_CREATE_ENDPOINT =
  "/masters/hr-tickets/create";
export const API_MASTER_FIELDS_CREATE_ENDPOINT = "/masters/fields/create";
export const API_MASTER_CLOUD_PROVIDER_CREATE_ENDPOINT =
  "/masters/clouds/create";
export const API_MASTER_CLOUD_SERVICES_CREATE_ENDPOINT =
  "/masters/cloud-services/create";
export const API_MASTER_CLOUD_SERVICE_FIELDS_CREATE_ENDPOINT =
  "/masters/cloud-service-fields/create";

export const API_MASTER_CUSTOMER_UPDATE_ENDPOINT = "/masters/customers/update";
export const API_MASTER_PROJECT_UPDATE_ENDPOINT = "/masters/projects/update";
export const API_MASTER_ROLE_UPDATE_ENDPOINT = "/masters/roles/update";
export const API_MASTER_PRIORITY_UPDATE_ENDPOINT = "/masters/priorities/update";
export const API_MASTER_CATEGORIES_UPDATE_ENDPOINT =
  "/masters/categories/update";
export const API_MASTER_STATUS_UPDATE_ENDPOINT = "/masters/status/update";
export const API_MASTER_HRTICKETS_UPDATE_ENDPOINT =
  "/masters/hr-tickets/update";
export const API_MASTER_FIELDS_UPDATE_ENDPOINT = "/masters/fields/update";
export const API_MASTER_CLOUD_PROVIDER_UPDATE_ENDPOINT =
  "/masters/clouds/update";
export const API_MASTER_CLOUD_SERVICES_UPDATE_ENDPOINT =
  "/masters/cloud-services/update";
export const API_MASTER_CLOUD_SERVICE_FIELDS_UPDATE_ENDPOINT =
  "/masters/cloud-service-fields/update";

/* MASTERS INVENTORY TYPE API STARTS */

export const API_MASTER_CREATE_INVENTORY_TYPE_ENDPOINT =
  "/masters/inventory-type/create";

export const API_MASTER_LIST_INVENTORY_TYPE_ENDPOINT =
  "/masters/inventory-type/list";

export const API_MASTER_UPDATE_INVENTORY_TYPE_ENDPOINT =
  "/masters/inventory-type/update";

export const API_MASTER_GET_INVENTORY_TYPE_ENDPOINT = "/masters/inventory-type";

export const API_MASTER_DELETE_INVENTORY_TYPE_ENDPOINT =
  "/masters/inventory-type/delete";

/* MASTERS INVENTORY TYPE API ENDS */

/* MASTERS INVENTORY CATEGORY  API STARTS */

export const API_MASTER_CREATE_INVENTORY_CATEGORY_ENDPOINT =
  "/masters/inventory-category/create";

export const API_MASTER_LIST_INVENTORY_CATEGORY_ENDPOINT =
  "/masters/inventory-category/list";

export const API_MASTER_UPDATE_INVENTORY_CATEGORY_ENDPOINT =
  "/masters/inventory-category/update";

export const API_MASTER_GET_INVENTORY_CATEGORY_ENDPOINT =
  "/masters/inventory-category";

export const API_MASTER_DELETE_INVENTORY_CATEGORY_ENDPOINT =
  "/masters/inventory-category/delete";

/* MASTERS INVENTORY CATEGORY  ENDS */

/* MASTERS WEBHOOK CONSUMER  API STARTS */

export const API_MASTER_CREATE_WEBHOOK_CONSUMER_ENDPOINT =
  "/masters/webhook-consumers/create";

export const API_MASTER_LIST_WEBHOOK_CONSUMER_ENDPOINT =
  "/masters/webhook-consumers/list";

export const API_MASTER_UPDATE_WEBHOOK_CONSUMER_ENDPOINT =
  "/masters/webhook-consumers/update";

export const API_MASTER_GET_WEBHOOK_CONSUMER_ENDPOINT =
  "/masters/webhook-consumers";

export const API_MASTER_DELETE_WEBHOOK_CONSUMER_ENDPOINT =
  "/masters/webhook-consumers/delete";

/* MASTERS WEBHOOK CONSUMER  ENDS */

/* MASTERS API ENDS */

/* DASHBOARD API START */
export const API_ANALYTICS_TICKET_GLIMPSE_ENDPOINT =
  "/analytics/ticket-glimpse";
export const API_ANALYTICS_TICKET_GLIMPSE_CATEGORY_WISE_ENDPOINT =
  "/analytics/ticket-glimpse/catergory-wise";
export const API_ANALYTICS_TICKET_TRENDS_ENDPOINT = "/analytics/ticket-trends";
export const ANALYTICS_TICKET_PROJECT_WISE_ENDPOINT =
  "/analytics/ticket-analytics/project-wise";
/* DASHBOARD API END */

/* TICKET MANAGEMENT API START */
export const API_TICKET_CREATE_ENDPOINT =
  "/ticket-management/tickets/create-ticket";
export const API_RAW_TICKET_CREATE_ENDPOINT =
  "/ticket-management/tickets/create-raw-ticket";
export const API_TICKET_DETAIL_ENDPOINT = "/ticket-management/tickets";
export const API_TICKET_LIST_ENDPOINT =
  "/ticket-management/tickets/list-tickets";
export const API_TICKET_TIMELINES_LIST_ENDPOINT =
  "/ticket-management/tickets/list-ticket-timelines";
export const API_TICKET_DELETE_ENDPOINT =
  "/ticket-management/tickets/delete-ticket";
export const API_TICKET_MARK_AS_PROBLEM_ENDPOINT =
  "/ticket-management/tickets/trigger-problem";
export const API_UPDATE_TICKET_PROPERTIES_ENDPOINT =
  "/ticket-management/tickets/update-ticket-properties";
export const API_UPDATE_TICKET_DETAILS_ENDPOINT =
  "/ticket-management/tickets/update-ticket";
export const API_UPDATE_TICKET_ASSIGNMENT_STATUS_ENDPOINT =
  "/ticket-management/tickets/ticket-assignment-status";
export const API_RCA_LIST_ENDPOINT = "/ticket-management/rca/list-rca";
export const API_RCA_DETAIL_ENDPOINT = "/ticket-management/rca/ticket";
export const API_RCA_CREATE_ENDPOINT = "/ticket-management/rca/create-rca";
export const API_RCA_UPDATE_ENDPOINT = "/ticket-management/rca/update-rca";
export const API_RCA_DELETE_ENDPOINT = "/ticket-management/rca/delete-rca";
export const API_BUDGET_DETAIL_ENDPOINT = "/ticket-management/budget/rca";
export const API_BUDGET_CREATE_ENDPOINT =
  "/ticket-management/budget/create-budget";
export const API_BUDGET_UPDATE_ENDPOINT =
  "/ticket-management/budget/update-budget";
/* TICKET MANAGEMENT API ENDS */

/* USERS API STARTS */
/* USERS API ENDS */

/* COMMENTS API STARTS */
export const API_COMMENTS_LIST_ENDPOINT =
  "/ticket-management/comments/list-comments";
export const API_CREATE_COMMENTS_ENDPOINT = "/ticket-management/comments";
/* COMMENTS API ENDS */

/* WEBHOOKS API STARTS */
export const API_LIST_WEBHOOK_ENDPOINT = "/webhooks/list";
export const API_GET_WEBHOOK_ENDPOINT = "/webhooks";
export const API_GET_WEBHOOK_DETAIL_ENDPOINT = "/webhooks/webhook-details";
export const API_CREATE_WEBHOOK_ENDPOINT = "/webhooks/create";
export const API_UPDATE_WEBHOOK_ENDPOINT = "/webhooks/update";
export const API_DELETE_WEBHOOK_ENDPOINT = "/webhooks/delete";
/* WEBHOOKS API ENDS */

/* ARTICLES API STARTS */
export const API_LIST_ARTICLES_ENDPOINT = "/articles/list-articles";
export const API_GET_ARTICLE_ENDPOINT = "/articles";
export const API_CREATE_ARTICLE_ENDPOINT = "/articles/create-article";
export const API_UPDATE_ARTICLE_ENDPOINT = "/articles/update-article";
export const API_DELETE_ARTICLE_ENDPOINT = "/articles/delete-article";
/* ARTICLES API ENDS */

/* NOTIFICATIONS API STARTS */
export const API_LIST_NOTIFICATIONS_ENDPOINT = "/notifications/list";
export const API_GET_NOTIFICATION_ENDPOINT = "/notifications";
export const API_MARK_NOTIFICATION_AS_SEEN_ENDPOINT = "/notifications/seen";
/* NOTIFICATIONS API ENDS */

/* GLOBAL API STARTS */
export const API_GLOBAL_SEARCH_ENDPOINT = "/search";
export const API_GLOBAL_GET_URL_FOR_FILE_UPLOAD =
  "/file-service/get-url-for-upload";
/* GLOBAL API ENDS */

/* CHANGE MANAGEMENT API ENDS */
export const API_CREATE_CHANGE_REQUEST_ENDPOINT = "/change-management/create";
export const API_LIST_CHANGE_REQUEST_ENDPOINT = "/change-management/list";
export const API_GET_CHANGE_REQUEST_ENDPOINT = "/change-management";
export const API_UPDATE_CHANGE_REQUEST_ENDPOINT = "/change-management/update";
export const API_UPDATE_CHANGE_REQUEST_STATUS_ENDPOINT =
  "/change-management/update-status";
export const API_DELETE_CHANGE_REQUEST_ENDPOINT = "/change-management/delete";
/* CHANGE MANAGEMENT API ENDS */

/* DASHBOARD API START */
export const API_DASHBOARD_TICKET_GLIMPSE_ENDPOINT =
  "/analytics/ticket-glimpse";
export const API_DASHBOARD_CATEGORY_WISE_TICKET_GLIMPSE_ENDPOINT =
  "/analytics/ticket-glimpse/category-wise";
export const API_DASHBOARD_TICKET_TRENDS_ENDPOINT = "/analytics/ticket-trends";
export const API_USER_TICKET_ANALYTICS_ENDPOINT =
  "/analytics/ticket-analytics/user-ticket-analytics";
export const API_DASHBOARD_PROJECT_WISE_TICKET_ANALYTICS_ENDPOINT =
  "/analytics/ticket-analytics/project-wise";
/* DASHBOARD API ENDS */

/* INVENTORY API START */
export const API_CREATE_INVENTRY_ENDPOINT = "/inventory-management/create";
export const API_LIST_INVENTRY_ENDPOINT = "/inventory-management/list";
export const API_UPDATE_INVENTRY_ENDPOINT = "/inventory-management/update";
export const API_GET_INVENTRY_ENDPOINT = "/inventory-management";
export const API_DELETE_INVENTRY_ENDPOINT = "/inventory-management/delete";
/* INVENTORY API ENDS */

/* CONFIG API START */
export const API_CREATE_CONFIG_ENDPOINT = "/configuration-management/create";
export const API_LIST_CONFIG_ENDPOINT = "/configuration-management/list";
export const API_UPDATE_CONFIG_ENDPOINT = "/configuration-management/update";
export const API_GET_CONFIG_ENDPOINT = "/configuration-management";
export const API_DELETE_CONFIG_ENDPOINT = "/configuration-management/delete";
/* CONFIG API ENDS */

/* SETTINGS API STARTS */
export const API_GET_THEME_ENDPOINT = "/settings/theme";
export const API_UPDATE_THEME_ENDPOINT = "/settings/theme";
/* SETTINGS API ENDS */

/* ROUTES STARTS */
export const APP_ROUTES = {
  ROUTE_ROOT: "/",
  ROUTE_SIGNUP: "/signup",
  ROUTE_LOGOUT: "/logout",
  ROUTE_OAUTH_ERROR: "/oauth/error",
  ROUTE_OAUTH_SUCCESS: "/oauth/success",
  ROUTE_RESET_PASSWORD: "/reset-password",
  ROUTE_REJECT_INVITATION: "/reject-invitation",
  ROUTE_FORGOT_PASSWORD: "/forgot-password",
  ROUTE_DASHBOARD: "/dashboard",
  ROUTE_REPORTS: "/reports",
  ROUTE_INCIDENT_MANAGEMENT: "/incident-management",
  ROUTE_BULK_TICKET_MANAGEMENT: "/bulk-tickets",
  ROUTE_PROBLEM_MANAGEMENT_BASE: "/problem-management",
  ROUTE_PROBLEM_MANAGEMENT: "/problem-management/problem-tickets",
  ROUTE_TICKET_DETAIL: "/ticket-detail/:id",
  ROUTE_RCA_MANAGEMENT: "/problem-management/rca",
  ROUTE_RCA_DETAIL: "/problem-management/rca/details/:id",
  ROUTE_USER_MANAGEMENT: "/user-management",
  ROUTE_USER_PROFILE: "/user-management/:id",
  ROUTE_WEBHOOK_MANAGEMENT: "/webhook-management",
  ROUTE_MASTER_MANAGEMENT: "/master-management",
  ROUTE_MASTER_TYPE_MANAGEMENT: "/master-management/:masterType?",
  ROUTE_CUSTOMER_DETAIL: "/master-management/customer/:id",
  ROUTE_PROJECT_DETAIL: "/master-management/project/:id",
  ROUTE_KNOWLEDGE_BASE: "/knowledge-base",
  ROUTE_KNOWLEDGE_BASE_ARTICLE: "/knowledge-base/:pagemode/:id?",
  ROUTE_SIDEBAR_COMMENT_PANEL: "?commentspanel=:id",
  ROUTE_NOTIFICATIONS: "/notifications",
  ROUTE_CHANGE_MANAGEMENT: "/change-management",
  ROUTE_CONFIG_MANAGEMENT: "/config-management",
  ROUTE_INVENTORY_MANAGEMENT: "/inventory-management",
  ROUTE_SETTINGS: "/settings",
  ROUTE_SETTINGS_TYPE: "/settings/:module?",
  ROUTE_WORKFLOW_MANAGEMENT: "/workflow-management",
};
/* ROUTES ENDS */

export const AUTH_ROUTES = [
  APP_ROUTES.ROUTE_LOGIN,
  APP_ROUTES.ROUTE_SIGNUP,
  APP_ROUTES.ROUTE_LOGOUT,
  APP_ROUTES.ROUTE_OAUTH_ERROR,
  APP_ROUTES.ROUTE_OAUTH_SUCCESS,
  APP_ROUTES.ROUTE_RESET_PASSWORD,
  APP_ROUTES.ROUTE_REJECT_INVITATION,
  APP_ROUTES.ROUTE_FORGOT_PASSWORD,
];

export const PAGE_MODES = {
  READ: "READ",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const ARTICLE_MODES = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
};

export const APP_CATEGORIES = {
  Priority: "Priority",
  Customers: "Customers",
  Projects: "Projects",
  Inventory: "Inventory",
  Configuration: "Configuration",
  Roles: "Roles",
  Status: "Status",
  Category: "Category",
  "HR Ticket": "HR Ticket",
  Field: "Field",
  Cloud: "Cloud",
  "Cloud Service": "Cloud Service",
  "Cloud Service Field": "Cloud Service Field",
  "Inventory Type": "Inventory Type",
  "Inventory Category": "Inventory Category",
  "Webhook Consumer": "Webhook Consumer",
  Ticket: "Ticket",
  Comment: "Comment",
  RCA: "RCA",
  Budget: "Budget",
  User: "User",
  "Knowledge Base": "Knowledge Base",
  Change: "Change",
  Theme: "Theme",
  Webhook: "Webhook",
};

export const ViewTypes = {
  CARD_VIEW: "CARD_VIEW",
  TABLE_VIEW: "TABLE_VIEW",
};

export const MASTER_MODULE_TYPE = {
  PRIORITY: "Priority",
  CUSTOMERS: "Customers",
  PROJECTS: `Projects`,
  ROLES: `Roles`,
  STATUS: `Status`,
  CATEGORY: `Category`,
  HR_TICKETS: `HR Ticket`,
  FIELDS: `Fields`,
  CLOUD_PROVIDERS: `Cloud Providers`,
  CLOUD_SERVICES: `Cloud Services`,
  CLOUD_SERVICE_FIELDS: `Cloud Service Fields`,
  INVENTORY_TYPE: `Inventory Type`,
  INVENTORY_CATEGORY: `Inventory Category`,
  WEBHOOK_CONSUMER: `Webhook Consumer`,
};

export const MASTER_MODULE_PATH = {
  PRIORITY: "priority",
  CUSTOMERS: "customers",
  PROJECTS: `projects`,
  ROLES: `roles`,
  STATUS: `status`,
  CATEGORY: `category`,
  HR_TICKETS: `hr-tickets`,
  FIELDS: `fields`,
  CLOUD_PROVIDERS: `cloud-providers`,
  CLOUD_SERVICES: `cloud-services`,
  CLOUD_SERVICE_FIELDS: `cloud-service-fields`,
  INVENTORY_TYPE: `inventory-type`,
  INVENTORY_CATEGORY: `inventory-category`,
  WEBHOOK_CONSUMER: `webhook-consumer`,
};

export const USER_INVITATION_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const SETTING_MODULES = {
  THEMING: "theming",
};

export const FontFamilies = [
  {
    label: "Poppins",
    path: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
    family: "'Poppins', sans-serif",
    value: "'Poppins', sans-serif",
  },
  {
    label: "Roboto",
    path: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
    family: "'Roboto', sans-serif",
    value: "'Roboto', sans-serif",
  },
  {
    label: "Open Sans",
    path: "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
    family: "'Open Sans', sans-serif",
    value: "'Open Sans', sans-serif",
  },
  {
    label: "Montserrat",
    path: "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
    family: "'Montserrat', sans-serif",
    value: "'Montserrat', sans-serif",
  },
  {
    label: "PT Sans",
    path: "https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap",
    family: "'PT Sans', sans-serif",
    value: "'PT Sans', sans-serif",
  },
  {
    label: "Raleway",
    path: "https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
    family: "'Raleway', sans-serif",
    value: "'Raleway', sans-serif",
  },
  {
    label: "Source Sans Pro",
    path: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap",
    family: "'Source Sans Pro', sans-serif",
    value: "'Source Sans Pro', sans-serif",
  },
  {
    label: "Lato",
    path: "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap",
    family: "'Lato', sans-serif",
    value: "'Lato', sans-serif",
  },
  {
    label: "Oswald",
    path: "https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap",
    family: "'Oswald', sans-serif",
    value: "'Oswald', sans-serif",
  },
  {
    label: "Sans Serif",
    path: "",
    family: "sans-serif",
    value: "sans-serif",
  },
];

export const DefaultTheme = {
  base: {
    baseFont: FontFamilies[0].value,
    headerFont: FontFamilies[0].value,
    backgroundColor: "#f3f7f9",
    baseColor: "#000000",
    headerColor: "#081a3c",
  },
  sidebar: {
    backgroundColor: "#0c2556",
    activeBackgroundColor: "#081a3c",
    color: "#ffffff",
    activeColor: "#ffffff",
  },
  card: {
    backgroundColor: "#ffffff",
    primaryColor: "#000000",
    lightPrimaryColor: "#3f4254",
    secondaryColor: "#b5b5c3",
    lightSecondaryColor: "#ebedf3",
  },
  table: {
    headerBackgroundColor: "#ffffff",
    bodyBackgroundColor: "#ffffff",
    headerColor: "#3f4254",
    bodyColor: "#3f4254",
    hoverBackgroundColor: "#e1f0ff",
    hoverColor: "#3f4254",
  },
  tile: {
    backgroundColor: "#ffffff",
    activeBackgroundColor: "#11a1fd",
    color: "#11a1fd",
    activeColor: "#ffffff",
  },
  button: {
    primaryBackgroundColor: "#11a1fd",
    secondaryBackgroundColor: "#ffffff",
    primaryColor: "#ffffff",
    secondaryColor: "#11a1fd",
  },
};

/* WORKFLOW API STARTS */
/* WORKFLOW API ENDS */
export const optimizeUsageTabs = {
  rightSizing: "Right Sizing",
  optimizeConfiguration: "Optimize Configuration",
  scheduleRecommendations: "Schedule Recommendations",
  manageIdle: "Manage Idle",
  manageOrphaned: "Manage Orphaned",
};

export const toastIds = {
  apiError: "apiError",
  downloadSuccess: "downloadSuccess",
  deleteSuccess: "deleteSuccess",
  dataIssue: "dataIssue",
  apiSuccess: "apiSuccess",
  successToastId: "success_toast",
  errorToastId: "error_toast",
};

export const ApiErrorMessages = {
  apiFailedMessage: "Service Unavailable. Please try again later.",
  errormsg: "Please enter key and value",
};

export const errorMessages = {
  dataNotFound: "Data not found",
  selectBudgetAmountAndbudgetGrowth:
    "To proceed, please select the budget amount and growth rate",
  startDateAndEndDateDiffNotExceedsMoreThanFourMonths:
    "Please ensure that the time period between the start date and end date is no more than four months",
};

export const CreateBudgetErrorMessage = {
  budgetNameRequired: "Please enter the budget name",
  endDateRequired: "Please enter end date",
  budgetNameMin3CharLong: "Budget name must be at least 3 characters long",
  selectTenant: "Please Select Tenant",
  selectCloudProvider: "Please Select Cloud Provider",
  selectCloudAccount: "Please Select Cloud Account",
  enterEndDate: "Please Enter End Date",
  notAValidBudgetName:
    "Not a valid budget name. Allowed characters are alphabets, numbers, _, #, - and space",
  enterBudgetAmount: "Please Enter Budget Amount",
  enterBudgetGrowth: "Please Enter Budget Growth",
};

export const CommonConstants = {
  System: "System",
  currentReservations: "Current Reservations",
  USD: "USD",
  AmazonEC2: "AmazonEC2",
  taggedUnTagged: "Tagged-UnTagged",
  AmazonElasticComputeCloudCompute: "Amazon Elastic Compute Cloud - Compute",
  RecommendationId: "7b4326eb-b8aa-45e6-9161-79329d4f1ddd",
  COST: "COST",
  recurring: "recurring",
  InvalidDate: "Invalid date",
  fixedValue: "fixedValue",
  FIXED: "FIXED",
  PLANNED: "PLANNED",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  expiring: "expiring",
  DAILY: "DAILY",
  custom: "custom",
  label: "How could time to diagnosis be improved ?",
};

export const loginApiHeaders = {
  "Content-Type": "application/json",
  Authorization: "Basic YnZkYTphY2NAYnZkYQ==",
};

export const BaseURL = "https://bvd.iseccorp.in"; //http://bvd-uat-api-lb-1600108620.ap-south-1.elb.amazonaws.com

export const boxDropdownOptions = ["Budget Configuration", "Markup & Discount"];

export const STATUSCODE = {
  OK: 200,
};

export const DateFormats = {
  dmy: "DD-MM-YYYY",
  ymd: "YYYY-MM-DD",
  optionsForConvertingDateIntoUTSTime: {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  },
};

export const ServicesInsightLayerTableHead = [
  { text: "", dataKey: "month", colSpan: 1 },
  { text: "Account ID", dataKey: "accountId", colSpan: 1 },
];

export const lastInsightsTableHead = [
  { text: "Account ID", dataKey: "accountId" },
  { text: "Account Name", dataKey: "accountName" },
  { text: "Linked Account Total", dataKey: "linkedAccountTotal" },
];

export const subHeadersLastInsights = [
  {
    text: "%diff",
    dataKey: "percentDiff",
  },
  {
    text: "cost",
    dataKey: "cost",
  },
];

export const basicBudgetDetailsValues = (data) => {
  return [
    {
      key: "Budget Name",
      value: data?.budgetName,
    },
    {
      key: "Budget Type",
      value: data?.budgetType,
    },
    {
      key: "Currency",
      value: data?.currencyType,
    },
    {
      key: "Budget Period",
      value: data?.budgetPeriod,
    },
    {
      key: "Budget Start Date",
      value: data?.startDate,
    },
    {
      key: "Budget Expiry Date",
      value: data?.expiryDate,
    },
    {
      key: "Budget Scope",
      value: data?.scope,
    },
  ];
};

export const downloadCSV = "Download CSV";

export const budgetAccountId = "203545962930";

export const errorOccurredMsg = "Some error occurred. Please try again later.";

export const filterCriteriaEditColumns = {
  budget: [
    "actions",
    "currency",
    "budgetStartDate",
    "budgetExpiryDate",
    "budgetId",
    "budgetEngine",
  ],
  optimizeUsageMain: [
    "potentialSavingsQuarterly",
    "potentialSavingsYearly",
    "forecastedSpendThisMonth",
    "cloudAccountId",
    "actions",
  ],
  optimizeUsageManagment: [
    "currentEstimatedCosts",
    "recommendationId",
    "recommendationCreatedOn",
    "recommendationSource",
    "actions",
  ],
  optimizeRateCurrentReservation: [
    "Purchased Hours",
    "Number of Reservations",
    "Reservation Hours Unused",
    "Reservation Id",
    "Cloud Account Id",
    "Tenant Id",
    "actions",
  ],
  posture: [
    "Account Type",
    "Scope",
    "Last Updated",
    "Currency",
    "Cloud Account IDs",
    "Tenant IDs",
    "Tenants",
    "actions",
  ],
  default: ["actions"],
};

export const localStorageValues = {
  OptimizeUsageTab: "OptimizeUsageTab",
  BudgetSelected: "BudgetSelected",
  costTrend: "costTrend",
  CloudProviderSelectedVal: "cloudProviderSelectedVal",
  TabSelected: "tab Selected",
  CloudAccountSelectedVal: "cloudAccountSelectedVal",
  OptimizeUsageSelectedRowManagment: "OptimizeUsageSelectedRowManagment",
  SelectedValueSelect: "SelectedValueSelect",
  OptimizeUsageManagmentListData: "OptimizeUsageManagmentListData",
  SelectedValueOptimizeRateTab: "SelectedValueOptimizeRateTab",
  CreateBudgetDetails: "CreateBudgetDetails",
};

export const localStoragePosture = {
  costTrend: "costTrend",
  costTrendDefault: "costTrendDefault",
  cloudAccountSelectedFilter: "cloudAccountSelectedPostureFilter",
};

export const defaultColumnsOptimizedUsageMain = [
  {
    id: "cloud" || "",
    title: "Cloud" || "",
  },
  {
    id: "cloudAccount" || "",
    title: "Cloud Account" || "",
  },
  {
    id: "impactedResourceCloud" || "",
    title: "Impacted Resource Cloud" || "",
  },
  {
    id: "potentialSavingsMonthly" || "",
    title: "Potential Savings (Monthly)" || "",
  },
  {
    id: "potentialSavingsQuarterly" || "",
    title: "Potential Savings (Quarterly)" || "",
  },
  {
    id: "potentialSavingsYearly" || "",
    title: "Potential Savings (Yearly)" || "",
  },
  {
    id: "forecastedSpendThisMonth" || "",
    title: "Forecasted Spend (This Month)" || "",
  },
  {
    id: "cloudAccountId" || "",
    title: "Cloud Account ID" || "",
  },
  {
    id: "actions" || "",
    title: "Actions" || "",
  },
];

export const defaultColumnsOptimizedUsageManagment = [
  {
    id: "resourceId" || "",
    title: "Resource Id" || "",
  },

  {
    id: "resourceName" || "",
    title: "Resource Name" || "",
  },
  {
    id: "resourceType" || "",
    title: "Resource Type" || "",
  },
  {
    id: "resource" || "",
    title: "Resource" || "",
  },
  {
    id: "potentialSavings" || "",
    title: "Potential Savings" || "",
  },
  {
    id: "region" || "",
    title: "Region" || "",
  },
  {
    id: "currentResourceSku" || "",
    title: "Current Resource SKU" || "",
  },
  {
    id: "recommendedResourceSku" || "",
    title: "Recommended Resource SKU" || "",
  },
  {
    id: "currentEstimatedCosts" || "",
    title: "Current Estimated Costs" || "",
  },
  {
    id: "recommendationId" || "",
    title: "Recommendation ID" || "",
  },
  {
    id: "recommendationCreatedOn" || "",
    title: "Recommendation Created On" || "",
  },
  {
    id: "recommendationSource" || "",
    title: "Recommendation Source" || "",
  },
  {
    id: "actions" || "",
    title: "Actions" || "",
  },
];

export const defaultColumnsPosture = [
  {
    id: "cloud" || "",
    title: "Cloud" || "",
  },
  {
    id: "cloudAccount" || "",
    title: "Cloud Account" || "",
  },
  {
    id: "totalCost" || "",
    title: "Total Cost" || "",
  },
  {
    id: "accountType" || "",
    title: "Account Type" || "",
  },
  {
    id: "scope" || "",
    title: "Scope" || "",
  },
  {
    id: "lastUpdated" || "",
    title: "Last Updated" || "",
  },
  {
    id: "currency" || "",
    title: "Currency" || "",
  },
  {
    id: "cloudAccountIds" || "",
    title: "Cloud Account IDs" || "",
  },
];

export const excludedColumnsPosture = [
  "Account Type",
  "Scope",
  "Last Updated",
  "Currency",
  "Cloud Account IDs",
  "Tenant IDs",
  "Tenants",
];

// export const token='eyJhbGciOiJIUzI1NiJ9.eyJpZCI6NSwianRpIjoic2Frc2hpLnNhcGthbGVAYWNjLmx0ZCIsImlhdCI6MTY5NzUyNDM0NSwiZXhwIjoxNzI5MTQ2NzQ1fQ.tQKc-x-e3QOQmcNyONzZHQjl_3l_dpVcJ-x8H6PiW9k';

export const defaultColumnsBudget = [
  {
    id: "budgetName" || "",
    title: "Budget Name" || "",
  },
  {
    id: "period" || "",
    title: "Period" || "",
  },
  {
    id: "budgeted" || "",
    title: "Budgeted" || "",
  },
  {
    id: "actualSpend" || "",
    title: "Actual Spend" || "",
  },
  {
    id: "forecasted" || "",
    title: "Forecasted" || "",
  },
  {
    id: "currency" || "",
    title: "Currency" || "",
  },
  {
    id: "budgetStartDate" || "",
    title: "Budget Start Date" || "",
  },
  {
    id: "budgetExpiryDate" || "",
    title: "Budget Expiry Date" || "",
  },
  {
    id: "actions" || "",
    title: "Actions" || "",
  },
];

export const defaultColumnsOptimizeRateCurrentReservation = [
  {
    id: "cloudAccount" || "",
    title: "Cloud Account" || "",
  },
  {
    id: "resourceType" || "",
    title: "Resource Type" || "",
  },
  {
    id: "reservationSKU" || "",
    title: "Reservation SKU" || "",
  },
  {
    id: "paymentOption" || "",
    title: "Payment Option" || "",
  },
  {
    id: "reservationHoursUsed" || "",
    title: "Reservation Hours Used" || "",
  },
  {
    id: "utilizationPercent" || "",
    title: "Utilization (%)" || "",
  },
  {
    id: "numberOfReservations" || "",
    title: "Number of Reservations" || "",
  },
  {
    id: "expiryDate" || "",
    title: "Expiry Date" || "",
  },
  {
    id: "reservationId" || "",
    title: "Reservation Id" || "",
  },

  {
    id: "cloudAccountId" || "",
    title: "Cloud Account Id" || "",
  },
  {
    id: "netSavings" || "",
    title: "Net Savings" || "",
  },
  {
    id: "actions" || "",
    title: "Actions" || "",
  },
];

export const defaultColumnsOptimizeRateRecommendedReservation = [
  {
    id: "cloud" || "",
    title: "Cloud" || "",
  },
  {
    id: "cloudAccount" || "",
    title: "Cloud Account" || "",
  },
  {
    id: "reservationType" || "",
    title: "Reservation Type" || "",
  },
  {
    id: "reservationSKU" || "",
    title: "Reservation SKU" || "",
  },
  {
    id: "region" || "",
    title: "Region" || "",
  },
  {
    id: "noOfReservations" || "",
    title: "No. of Reservations" || "",
  },
  {
    id: "term" || "",
    title: "Term" || "",
  },
  {
    id: "potentialSavings" || "",
    title: "Potential Savings" || "",
  },
];

export const basicFilterDetailsValues = (data) => {
  return [
    // {
    //   key: "Tenant",
    //   value: data?.tenant,
    // },
    {
      key: "Cloud Account",
      value: data?.cloudAccount,
    },
  ];
};

export const ImpactLevel = [
  {
    key: "Low",
    value: "Low",
  },
  {
    key: "Medium",
    value: "Medium",
  },
  {
    key: "High",
    value: "High",
  },
];

export const OptimizationStatus = [
  {
    key: "Open",
    value: "Open",
  },
  {
    key: "Resolved",
    value: "Resolved",
  },
  {
    key: "Skipped",
    value: "Skipped",
  },
];

export const RecommendedSource = [
  {
    key: "All",
    value: "All",
  },
  {
    key: "Native",
    value: "Native",
  },
  {
    key: "System",
    value: "System",
  },
];

export const Cloud = [
  {
    key: "Aws",
    value: "Aws",
  },
];

export const AccountType = [
  {
    key: "linked_Account",
    value: "linked_Account",
  },
  {
    key: "master Account",
    value: "master Account",
  },
];

export const ThresholdValues = (data) => {
  return data?.map((data) => [
    {
      key: "Threshold Setup",
      value: data?.setup,
    },
    {
      key: "% of Budgeted Amount ",
      value: data?.percentOfBudgetedAmt,
    },
    {
      key: "Amount",
      value: data?.Amount,
    },
    {
      key: "notificationEmails",
      value: data?.notificationEmails,
    },
  ]);
};
export const AnamolyTableHeaderData = () => {
  return [
    { key: "date", header: "Date" },
    { key: "cloudType", header: "Cloud Provider" },
    { key: "cloudAccount", header: "Cloud Account" },
    { key: "productCategory", header: "Product Category" },
    { key: "resourceCategory", header: "Resource Category" },
    { key: "costImpact", header: "Cost Impact" },
  ];
};

export const DONWLOAD_TYPE = {
  csv: "CSV",
  png: "PNG",
};

export const LIMIT_ITEM = {
  allData: "All Data",
  topFive: "Top 5",
  topFiveAndOthers: "Top 5 plus others",
  bottomFive: "Bottom 5",
  bottomFiveAndOthers: "Bottom 5 plus others",
};
export const VIEW_AS_TYPE = {
  line: "Line",
  map: "Map",
  bar: "Bar",
  wordCloud: "Word Cloud ",
  horizontalBar: "Horizontal Bar",
  datatable: "data table",
  horizontalBarChart: "horizontal Bar Chart",
  horizontalWideBar: "Horizontal Wide Bar",
  variwide: "Variwide",
  area: "Area",
  bubble: "Bubble",
  treeMap: "Tree Map",
  stackedBarAndLineGraph: "Stacked Bar And Line Mix",
  barAndLineGraph: "Bar And Line Mix",
  sunBurst: "Sun Burst",
  stackedBar: "Stacked Bar",
  sankey: "Sankey",
  donut: "Donut",
  donut: "Donut",
  pie: "Pie",
  heatmap: "Heat Map",
  groupedColumn: "Grouped Column",
  lineBar: "Line Bar",
  dataTable: "Data Table",
  lineChart: "Line Chart",
  joinedBarGraph: "Joined Bar Graph",
};

export const TIME_RANGE = {
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  LAST_7_DAYS: "Last 7 days",
  LAST_30_DAYS: "Last 30 days",
  CURRENT_YEAR: "Current Year",
  PREVIOUS_YEAR: "Previous Year",
  CURRENT_QUARTER: "Current Quarter",
  PREVIOUS_QUARTER: "Previous Quarter",
  CUSTOM_RANGE: "Custom Range",
};

export const CommonApiFilter = {
  filter: {},
  limit: 20,
  page: 1,
  sort: {},
};

export const API_ASSIGNEES_LIST_ENDPOINT = "/users/list-assignees";
export const API_USER_LIST_ENDPOINT = "/users/list";

export const API_UPDATE_USER_ENDPOINT = "/users/update";
export const API_REINVITE_USER_ENDPOINT = "/users/resend-invitation";
export const API_INVITE_USER_ENDPOINT = "/users/invite";
export const API_GET_USER_ENDPOINT = "/users";
export const API_DELETE_USER_ENDPOINT = "/users/delete";
export const API_LIST_TAGS_ENDPOINT = "/tags/list";
export const API_USER_LOGIN_ENDPOINT = "/users/login";

export const API_CREATE_TAG_ENDPOINT = "/tags/create";
export const Messages = {
  SOMETHING_WENT_WRONG: "Something went wrong.",
  BAD_REQUEST: "Bad Request.",
  UNABLE_TO_PROCESS_REQUEST: "We are not able to process your request.",
  REQUEST_PROCESSED: "Your request was successfully processed.",
  EMAIL_HAS_BEEN_SENT: "An email has been initiated.",
  SUCCESS: "Successfull.",
  USER_INVITED: "Invitation has been sent to the user.",
  USER_REINVITE: "Invitation has been sent to the user.",
  USER_UPDATED: "Profile updated.",
  USER_SENDING_INVITE: "Sending Invite.",
  INITIATING_DELETE: "Initiating Delete.",
  USER_DELETED: "User Deleted.",
  TICKET_DELETED: "Ticket has been deleted.",
  TICKET_UPDATED: "Ticket has been updated.",
  RCA_DELETED: "RCA has been deleted.",
  RCA_CREATED: "RCA has been created.",
  RCA_UPDATED: "RCA has been updated.",
  WEBHOOK_DELETED: "Webhook has been deleted.",
  WEBHOOK_CREATED: "Webhook has been created.",
  WEBHOOK_UPDATED: "Webhook has been updated.",
  BUDGET_DELETED: "Budget has been deleted.",
  BUDGET_CREATED: "Budget has been created.",
  BUDGET_UPDATED: "Budget has been updated.",
  TICKET_MARKING_AS_PROBLEM: "Marking Ticket as Problem.",
  TICKET_MARKED_AS_PROBLEM: "Ticket Marked as Problem.",
  TICKET_CLOSING: "Closing Ticket.",
  TICKET_CLOSED: "Ticket Closed.",
  TICKET_CREATED: "Your ticket has been created.",
  CONFIG_CREATED: "Configuration has been created.",
  CONFIG_UPDATED: "Configuration has been updated.",
  CONFIG_DELETED: "Configuration has been deleted.",
  INVENTORY_CREATED: "Inventory has been created.",
  INVENTORY_UPDATED: "Inventory has been updated.",
  INVENTORY_DELETED: "Inventory has been deleted.",
  UPDATING: "Updating.",
  CHANGE_REQUEST_CREATED: "Change Request created.",
  CHANGE_REQUEST_UPDATED: "Change Request updated.",
  CHANGE_REQUEST_DELETED: "Change Request deleted.",
  CHANGE_REQUEST_STATUS_CHANGED: "Change Request Status updated.",
  THEME_PUBLISHED: "Theme has been published globally.",
  NO_TICKETS_FOUND_IN_FILE: "No Tickets found in the file.",
  TICKET_SAVED: "Your ticket has been saved.",
  WORKFLOW_CREATED: "Your Workflow has been created.",
  WORKFLOW_CREATION_FAILED: "Your Workflow creation failed.",
};

export const API_CREATE_WORKFLOW = "/ticketWorkflows/create";

export const CUSTOM_EVENT_TYPES = {
  REFRESH_TICKET_MODULE: "refresh_ticket_module",
  REFRESH_CR_MODULE: "refresh_cr_module",
  HANDLE_DELETE_NODE: "DELETE_NODE",
  HANDLE_GET_NODE_CONFIGURATION: "HANDLE_GET_NODE_CONFIGURATION",
};

export const CONSTANTS = {
  COSTOPTIMIZER_ACCOUNT_ID: 465171094456,
  ACCOUNT_ID: 766940073591,
  xAcessToken:
    "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MTUsImp0aSI6ImVyZW5AYWNjLmx0ZCIsImlhdCI6MTY5NDA3MjI0NSwiZXhwIjoxNjk2NjY0MjQ1fQ.7uTab6Gb38yvwSXCs7lGlC8qGKwFlvF2ZRdEyLk5b1w",
  DOWNLOAD: {
    type: "submenu",
    name: "Download",
    children: [
      {
        type: "menuItem",
        name: DONWLOAD_TYPE.csv,
      },
      {
        type: "menuItem",
        name: DONWLOAD_TYPE.png,
      },
    ],
  },
  LIMIT_DATA: {
    type: "submenu",
    name: "Limit Data",
    children: [
      {
        type: "menuItem",
        name: LIMIT_ITEM.allData,
      },
      {
        type: "menuItem",
        name: LIMIT_ITEM.topFive,
      },
      {
        type: "menuItem",
        name: LIMIT_ITEM.topFiveAndOthers,
      },
      {
        type: "menuItem",
        name: LIMIT_ITEM.bottomFive,
      },
      {
        type: "menuItem",
        name: LIMIT_ITEM.bottomFiveAndOthers,
      },
    ],
  },
  VIEW_AS: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.line,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.map,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.bar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.horizontalBar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.horizontalWideBar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.variwide,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.area,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.bubble,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.treeMap,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.sunBurst,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.stackedBar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.sankey,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.donut,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.pie,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.heatmap,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.groupedColumn,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.lineBar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.dataTable,
      },
    ],
  },
  VIEW_AS_FOR_COST_OPTIMIZATION_BY_USAGE: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.groupedColumn,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.treeMap,
      },
    ],
  },

  CURRENCY_DATA: ["USD"],
  CLOUD_PROVIDER_DATA: ["AWS"],
  CURRENCY: {
    USD: "$",
  },
  TIME_RANGE_DATA: Object.values(TIME_RANGE).map((x) => x),
  VIEW_AS_CLOUD_SPEND_TREND: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.line,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.area,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.heatmap,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.dataTable,
      },
    ],
  },
  VIEW_AS_TENANT_SPEND: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.bar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.groupedColumn,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.stackedBar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.pie,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.donut,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.treeMap,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.dataTable,
      },
    ],
  },
  VIEW_AS_SPEND_BY_REGION: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.map,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.sunBurst,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.dataTable,
      },
    ],
  },
  VIEW_AS_SAVINGs_BY_REGION: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.map,
      },
      // {
      //   type: "menuItem",
      //   name: VIEW_AS_TYPE.dataTable,
      // },
    ],
  },
  VIEW_AS_SPEND_BY_CATEGORY: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.horizontalBar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.pie,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.donut,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.treeMap,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.bubble,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.dataTable,
      },
    ],
  },
  VIEW_AS_BUDGET: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.stackedBar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.dataTable,
      },
    ],
  },
  VIEW_AS_CLOUD_SPEND_FORECAST: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.lineBar,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.dataTable,
      },
    ],
  },
  VIEW_AS_TAG_LEVEL_BY_CLOUD: {
    type: "submenu",
    name: "View As",
    children: [
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.groupedColumn,
      },
      {
        type: "menuItem",
        name: VIEW_AS_TYPE.dataTable,
      },
    ],
  },
  EMPTY_DATA: {
    data: [],
    tableData: [],
    header: [],
  },
  CARDS_EMPTY_DATA: {
    cloudSpend: 0,
    topAccount: 0,
    topAccountName: "",
    topCloud: 0,
    topRegion: 0,
    topRegionName: "",
  },
};

export const markupAndDiscountErrors = {
  ruleNameRequired: "Rule Name is required",
  ruleNameInvalid: "Rule Name is invalid",
  scopeRequired: "Scope is required",
  ruleTypeRequired: "Rule Type is required",
  discountRequired: "Discount is required",
  discountShouldBePositiveNumber:
    "The discount should be expressed as a positive number.",
  planNameRequired: "Plan Name is required",
  planNameInvalid: "Plan Name is invalid",
  groupNameRequired: "Group Name is required",
  groupNameInvalid: "Group Name is invalid",
  planDescriptionRequired: "Plan Description is required",
  groupDescriptionRequired: "Group Description is required",
};

export const PeriodOptionsFilterBudget = [
  { key: "All", value: "All" },
  { key: "Daily", value: "DAILY" },
  { key: "Monthly", value: "MONTHLY" },
  { key: "Quarterly", value: "QUARTERLY" },
  { key: "Annually", value: "ANNUALLY" },
];
export const downloadApiMessage = {
  success: "File downloaded successfully",
  error: "An error occurred while downloading the file",
};
export const addedFilter = "Filter added successfully";
export const Filetype = {
  excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const FileName = {
  RightSizing: "RightSizing.csv",
  BudgetFile: "BudgetFile.csv",
  OptimizeAction: "optimizeAction.csv",
  POSTURE_SUMMARY: "summary.csv",
  POSTURE_PRODUCT: "productCategory.csv",
  POSTURE_REGION: "region.csv",
  TAGS: "tags.csv",
  UNTAGS: "untags.csv",
  UNTAGED_RESOURCES: "unTagedResources.csv",
};

export const POSTURE_DATA = {
  CLOUD_PROVIDER_DATA: ["AWS"],
  POSTURE_ACCOUNT_TYPE: ["linked_account", "master_account"],
  CURRENCY_DATA: [{ key: "US Dollar (USD)", value: "US Dollar (USD)" }],
  DATA: ["Summary", "Product Category", "Region", "Tags"],
  TENANT_REPORT_DATA: [
    { key: "Aws_cost", value: "Aws_cost" },
    { key: "Euro_Aws_cost", value: "Euro_Aws_cost" },
  ],
  CHECK_REGION: [
    { id: 1, name: "Region RDS" },
    { id: 2, name: "Region Cost Explorer" },
    { id: 3, name: "Region CodeCommmit" },
    { id: 4, name: "Region Cloud Trail" },
  ],
  CHART_TYPE: {
    area: "area",
    bar: "bar",
    line: "line",
  },
  VIEW_TYPE: {
    REGION: "Region",
    TAGS: "Tags",
    PRODUCT_CATEGORY: "Product Category",
    SUMMARY: "Summary",
  },
  GRAPH_OPTIONS: [
    { id: 2, value: "Download PNG image" },
    { id: 3, value: "Download CSV" },
    { id: 4, value: "Download XLS" },
    { id: 5, value: "Download PDF document" },
  ],
  PRODUCT_CATEGORY_DATA: [
    { id: 1, value: "Top 5 Product Category" },
    { id: 2, value: "Top 10 Product Category" },
    { id: 3, value: "Custom" },
  ],
  REGION_DATA: [
    { id: 1, value: "Top 5 Region" },
    { id: 2, value: "Top 10 Region" },
    { id: 3, value: "Custom" },
  ],
  TAGS_DATA: [
    { id: 1, value: "Tagged-UnTagged" },
    { id: 2, value: "Top 5 Key-Value" },
    { id: 3, value: "Top 10 Key-Value" },
  ],
  RADIO_OPTION_DATA: [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ],
  OPTION_DATA: [
    { label: "Option 1", value: "option11" },
    { label: "Option 22", value: "option22" },
  ],
  ADVANCED_FILTER: {
    PERIOD: "Period",
    CLOUD_ACCOUNT: "CloudAccount",
    TAGS: "Tags",
    FILE_COMPILATION: "FileCompilation",
  },
  REDIRECT_OPTIONS: [
    {
      label: "Budget Configuration",
      route: ROUTE_PATH.BILLING.BUDGET,
    },
    {
      label: "Markup & Discount",
      route: ROUTE_PATH.BILLING.MARKUP_AND_DISCOUNTS,
    },
  ],
  POSTURE_VIEWS: ["Account View", "Tenant View"],
};

export const DASHBOARD_CHART = {
  cloudSpendTrend: "cloudSpendTrend",
  tenantSpend: "tenantSpend",
  spendByRegion: "spendByRegion",
  spendByCategory: "spendByCategory",
  spendByProductCategory: "spendByProductCategory",
  cloudSpendForecast: "cloudSpendForecast",
  tagLevelByCloud: "tagLevelByCloud",
  budgetDrift: "budgetDrift",
  budgetForecast: "budgetForecast",
  topBudgetByTenants: "topBudgetByTenants",
};

export const DEFAULT_TABLE_DETAILS = {
  limit: 10,
  offset: 0,
};

//kpi dummy data
export const ec2GraphOptionalData = [
  {
    type: "submenu",
    name: "Download",
    children: [
      {
        type: "menuItem",
        name: "CSV",
      },
      {
        type: "menuItem",
        name: "PNG",
      },
    ],
  },
];

export const ec2SelectDateOptions = ["CPU"];

export const kpiDashboardGroupByOptions = [
  "All",
  "Account Id",
  "Account Name",
  "Payer Account Id",
];
export const kpiDashboardAccountNameOptions = [
  "All",
  "Account +/AW",
  "Account +/30Z",
  "Account +/AJI",
  "Account +/D2S",
];
export const kpiDashboardAccountIdOptions = [
  "Account +/AW",
  "Account +/30Z",
  "Account +/AJI",
  "Account +/D2S",
];

export const ec2TableHeaders = [
  { id: "region", label: "Region" },
  { id: "resourceId", label: "Resource ID" },
  { id: "instanceFamily", label: "Instance Family" },
  { id: "latestGraviton", label: "Latest Graviton" },
  { id: "latestAmd", label: "Latest Amd" },
  { id: "instanceType", label: "Instance Type" },
  { id: "amdPotentialSavings", label: "AMD Potential Savings" },
  { id: "gravitonPotentialSavings", label: "Graviton Potential Savings " },
  { id: "cost", label: "Cost" },
];

export const ec2TableData = [
  {
    region: "Region 0",
    resourceId: 25,
    instanceFamily: "t2",
    latestGraviton: "Graviton 1",
    latestAmd: "AMD 1",
    instanceType: "Instance Type 1",
    amdPotentialSavings: "$10",
    gravitonPotentialSavings: "$15",
    cost: "$20",
  },
  {
    region: "Region 1",
    resourceId: 26,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 1",
    latestAmd: "AMD 1",
    instanceType: "Instance Type 1",
    amdPotentialSavings: "$10",
    gravitonPotentialSavings: "$15",
    cost: "$20",
  },
  {
    region: "Region 2",
    resourceId: 27,
    instanceFamily: "t2",
    latestGraviton: "Graviton 2",
    latestAmd: "AMD 2",
    instanceType: "Instance Type 2",
    amdPotentialSavings: "$20",
    gravitonPotentialSavings: "$30",
    cost: "$40",
  },
  {
    region: "Region 3",
    resourceId: 28,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 3",
    latestAmd: "AMD 3",
    instanceType: "Instance Type 3",
    amdPotentialSavings: "$30",
    gravitonPotentialSavings: "$45",
    cost: "$60",
  },
  {
    region: "Region 4",
    resourceId: 29,
    instanceFamily: "t2",
    latestGraviton: "Graviton 4",
    latestAmd: "AMD 4",
    instanceType: "Instance Type 4",
    amdPotentialSavings: "$40",
    gravitonPotentialSavings: "$60",
    cost: "$80",
  },
  {
    region: "Region 5",
    resourceId: 30,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 5",
    latestAmd: "AMD 5",
    instanceType: "Instance Type 5",
    amdPotentialSavings: "$50",
    gravitonPotentialSavings: "$75",
    cost: "$100",
  },
  {
    region: "Region 6",
    resourceId: 31,
    instanceFamily: "t2",
    latestGraviton: "Graviton 6",
    latestAmd: "AMD 6",
    instanceType: "Instance Type 6",
    amdPotentialSavings: "$60",
    gravitonPotentialSavings: "$90",
    cost: "$120",
  },
  {
    region: "Region 7",
    resourceId: 32,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 7",
    latestAmd: "AMD 7",
    instanceType: "Instance Type 7",
    amdPotentialSavings: "$70",
    gravitonPotentialSavings: "$105",
    cost: "$140",
  },
  {
    region: "Region 8",
    resourceId: 33,
    instanceFamily: "t2",
    latestGraviton: "Graviton 8",
    latestAmd: "AMD 8",
    instanceType: "Instance Type 8",
    amdPotentialSavings: "$80",
    gravitonPotentialSavings: "$120",
    cost: "$160",
  },
  {
    region: "Region 9",
    resourceId: 34,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 9",
    latestAmd: "AMD 9",
    instanceType: "Instance Type 9",
    amdPotentialSavings: "$90",
    gravitonPotentialSavings: "$135",
    cost: "$180",
  },
  {
    region: "Region 10",
    resourceId: 35,
    instanceFamily: "t2",
    latestGraviton: "Graviton 10",
    latestAmd: "AMD 10",
    instanceType: "Instance Type 10",
    amdPotentialSavings: "$100",
    gravitonPotentialSavings: "$150",
    cost: "$200",
  },
  {
    region: "Region 11",
    resourceId: 36,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 11",
    latestAmd: "AMD 11",
    instanceType: "Instance Type 11",
    amdPotentialSavings: "$110",
    gravitonPotentialSavings: "$165",
    cost: "$220",
  },
  {
    region: "Region 12",
    resourceId: 37,
    instanceFamily: "t2",
    latestGraviton: "Graviton 12",
    latestAmd: "AMD 12",
    instanceType: "Instance Type 12",
    amdPotentialSavings: "$120",
    gravitonPotentialSavings: "$180",
    cost: "$240",
  },
  {
    region: "Region 13",
    resourceId: 38,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 13",
    latestAmd: "AMD 13",
    instanceType: "Instance Type 13",
    amdPotentialSavings: "$130",
    gravitonPotentialSavings: "$195",
    cost: "$260",
  },
  {
    region: "Region 14",
    resourceId: 39,
    instanceFamily: "t2",
    latestGraviton: "Graviton 14",
    latestAmd: "AMD 14",
    instanceType: "Instance Type 14",
    amdPotentialSavings: "$140",
    gravitonPotentialSavings: "$210",
    cost: "$280",
  },
  {
    region: "Region 15",
    resourceId: 40,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 15",
    latestAmd: "AMD 15",
    instanceType: "Instance Type 15",
    amdPotentialSavings: "$150",
    gravitonPotentialSavings: "$225",
    cost: "$300",
  },
  {
    region: "Region 16",
    resourceId: 41,
    instanceFamily: "t2",
    latestGraviton: "Graviton 16",
    latestAmd: "AMD 16",
    instanceType: "Instance Type 16",
    amdPotentialSavings: "$160",
    gravitonPotentialSavings: "$240",
    cost: "$320",
  },
  {
    region: "Region 17",
    resourceId: 42,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 17",
    latestAmd: "AMD 17",
    instanceType: "Instance Type 17",
    amdPotentialSavings: "$170",
    gravitonPotentialSavings: "$255",
    cost: "$340",
  },
  {
    region: "Region 18",
    resourceId: 43,
    instanceFamily: "t2",
    latestGraviton: "Graviton 18",
    latestAmd: "AMD 18",
    instanceType: "Instance Type 18",
    amdPotentialSavings: "$180",
    gravitonPotentialSavings: "$270",
    cost: "$360",
  },
  {
    region: "Region 19",
    resourceId: 44,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 19",
    latestAmd: "AMD 19",
    instanceType: "Instance Type 19",
    amdPotentialSavings: "$190",
    gravitonPotentialSavings: "$285",
    cost: "$380",
  },
  {
    region: "Region 20",
    resourceId: 45,
    instanceFamily: "t2",
    latestGraviton: "Graviton 20",
    latestAmd: "AMD 20",
    instanceType: "Instance Type 20",
    amdPotentialSavings: "$200",
    gravitonPotentialSavings: "$300",
    cost: "$400",
  },
  {
    region: "Region 21",
    resourceId: 46,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 21",
    latestAmd: "AMD 21",
    instanceType: "Instance Type 21",
    amdPotentialSavings: "$210",
    gravitonPotentialSavings: "$315",
    cost: "$420",
  },
  {
    region: "Region 22",
    resourceId: 47,
    instanceFamily: "t2",
    latestGraviton: "Graviton 22",
    latestAmd: "AMD 22",
    instanceType: "Instance Type 22",
    amdPotentialSavings: "$220",
    gravitonPotentialSavings: "$330",
    cost: "$440",
  },
  {
    region: "Region 23",
    resourceId: 48,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 23",
    latestAmd: "AMD 23",
    instanceType: "Instance Type 23",
    amdPotentialSavings: "$230",
    gravitonPotentialSavings: "$345",
    cost: "$460",
  },
  {
    region: "Region 24",
    resourceId: 49,
    instanceFamily: "t2",
    latestGraviton: "Graviton 24",
    latestAmd: "AMD 24",
    instanceType: "Instance Type 24",
    amdPotentialSavings: "$240",
    gravitonPotentialSavings: "$360",
    cost: "$480",
  },
  {
    region: "Region 25",
    resourceId: 50,
    instanceFamily: "c5a",
    latestGraviton: "Graviton 25",
    latestAmd: "AMD 25",
    instanceType: "Instance Type 25",
    amdPotentialSavings: "$250",
    gravitonPotentialSavings: "$375",
    cost: "$500",
  },
];

export const EBSVolumeLookupTableData = [
  {
    volumeId: "+HAWDCZXCR",
    volumeType: "io1",
    dec2023Cost: "$700.00",
    dec2023Size: "903",
    dec2023IOPs: "9,032",
    dec2023Throughput: "0",
    jan2024Cost: "$532.00",
    jan2024Size: "323",
    jan2024IOPs: "3,123",
    jan2024Throughput: "0",
    feb2023Cost: "$675.00",
    feb2023Size: "532",
    feb2023IOPs: "4,123",
    feb2023Throughput: "0",
    march2023Cost: "$513.00",
    march2023Size: "654",
    march2023IOPs: "5,243",
    march2023Throughput: "0",
  },
  {
    volumeId: "+YCXEZRSHDW",
    volumeType: "gp2",
    dec2023Cost: "$600.00",
    dec2023Size: "720",
    dec2023IOPs: "7,200",
    dec2023Throughput: "0",
    jan2024Cost: "$432.00",
    jan2024Size: "240",
    jan2024IOPs: "2,400",
    jan2024Throughput: "0",
    feb2023Cost: "$540.00",
    feb2023Size: "600",
    feb2023IOPs: "6,000",
    feb2023Throughput: "0",
    march2023Cost: "$400.00",
    march2023Size: "500",
    march2023IOPs: "5,000",
    march2023Throughput: "0",
  },
  {
    volumeId: "+ASWDRFGTVB",
    volumeType: "gp3",
    dec2023Cost: "$800.00",
    dec2023Size: "1000",
    dec2023IOPs: "10,000",
    dec2023Throughput: "0",
    jan2024Cost: "$600.00",
    jan2024Size: "400",
    jan2024IOPs: "4,000",
    jan2024Throughput: "0",
    feb2023Cost: "$720.00",
    feb2023Size: "800",
    feb2023IOPs: "8,000",
    feb2023Throughput: "0",
    march2023Cost: "$560.00",
    march2023Size: "700",
    march2023IOPs: "7,000",
    march2023Throughput: "0",
  },
  {
    volumeId: "+RTFYUGYHJI",
    volumeType: "io1",
    dec2023Cost: "$900.00",
    dec2023Size: "1100",
    dec2023IOPs: "11,000",
    dec2023Throughput: "0",
    jan2024Cost: "$720.00",
    jan2024Size: "500",
    jan2024IOPs: "5,000",
    jan2024Throughput: "0",
    feb2023Cost: "$850.00",
    feb2023Size: "900",
    feb2023IOPs: "9,000",
    feb2023Throughput: "0",
    march2023Cost: "$650.00",
    march2023Size: "800",
    march2023IOPs: "8,000",
    march2023Throughput: "0",
  },
  {
    volumeId: "+XDCFVGBHNJ",
    volumeType: "gp2",
    dec2023Cost: "$750.00",
    dec2023Size: "800",
    dec2023IOPs: "8,000",
    dec2023Throughput: "0",
    jan2024Cost: "$580.00",
    jan2024Size: "420",
    jan2024IOPs: "4,200",
    jan2024Throughput: "0",
    feb2023Cost: "$690.00",
    feb2023Size: "730",
    feb2023IOPs: "7,300",
    feb2023Throughput: "0",
    march2023Cost: "$520.00",
    march2023Size: "650",
    march2023IOPs: "6,500",
    march2023Throughput: "0",
  },
  {
    volumeId: "+NBVCXZLKJH",
    volumeType: "gp3",
    dec2023Cost: "$850.00",
    dec2023Size: "920",
    dec2023IOPs: "9,200",
    dec2023Throughput: "0",
    jan2024Cost: "$670.00",
    jan2024Size: "520",
    jan2024IOPs: "5,200",
    jan2024Throughput: "0",
    feb2023Cost: "$790.00",
    feb2023Size: "830",
    feb2023IOPs: "8,300",
    feb2023Throughput: "0",
    march2023Cost: "$610.00",
    march2023Size: "750",
    march2023IOPs: "7,500",
    march2023Throughput: "0",
  },
  {
    volumeId: "+VBNMKIJUHG",
    volumeType: "io1",
    dec2023Cost: "$950.00",
    dec2023Size: "1300",
    dec2023IOPs: "13,000",
    dec2023Throughput: "0",
    jan2024Cost: "$780.00",
    jan2024Size: "600",
    jan2024IOPs: "6,000",
    jan2024Throughput: "0",
    feb2023Cost: "$890.00",
    feb2023Size: "1000",
    feb2023IOPs: "10,000",
    feb2023Throughput: "0",
    march2023Cost: "$720.00",
    march2023Size: "900",
    march2023IOPs: "9,000",
    march2023Throughput: "0",
  },
  {
    volumeId: "+LKJHGFDSAA",
    volumeType: "gp2",
    dec2023Cost: "$800.00",
    dec2023Size: "900",
    dec2023IOPs: "9,000",
    dec2023Throughput: "0",
    jan2024Cost: "$630.00",
    jan2024Size: "520",
    jan2024IOPs: "5,200",
    jan2024Throughput: "0",
    feb2023Cost: "$740.00",
    feb2023Size: "830",
    feb2023IOPs: "8,300",
    feb2023Throughput: "0",
    march2023Cost: "$570.00",
    march2023Size: "750",
    march2023IOPs: "7,500",
    march2023Throughput: "0",
  },
  {
    volumeId: "+POIUYYTRE",
    volumeType: "gp3",
    dec2023Cost: "$900.00",
    dec2023Size: "1000",
    dec2023IOPs: "10,000",
    dec2023Throughput: "0",
    jan2024Cost: "$720.00",
    jan2024Size: "600",
    jan2024IOPs: "6,000",
    jan2024Throughput: "0",
    feb2023Cost: "$840.00",
    feb2023Size: "800",
    feb2023IOPs: "8,000",
    feb2023Throughput: "0",
    march2023Cost: "$650.00",
    march2023Size: "900",
    march2023IOPs: "9,000",
    march2023Throughput: "0",
  },
  {
    volumeId: "+AZSXCFVVVB",
    volumeType: "io1",
    dec2023Cost: "$1000.00",
    dec2023Size: "1500",
    dec2023IOPs: "15,000",
    dec2023Throughput: "0",
    jan2024Cost: "$820.00",
    jan2024Size: "700",
    jan2024IOPs: "7,000",
    jan2024Throughput: "0",
    feb2023Cost: "$940.00",
    feb2023Size: "1200",
    feb2023IOPs: "12,000",
    feb2023Throughput: "0",
    march2023Cost: "$780.00",
    march2023Size: "1100",
    march2023IOPs: "11,000",
    march2023Throughput: "0",
  },
];

export const ebsBarAndGraphData = {
  data: [
    {
      name: "gp2",
      type: "bar",
      data: [2.0, 4.9, 7.0, 23.2],
    },
    {
      name: "gp3",
      type: "bar",
      data: [2.6, 5.9, 9.0, 26.4],
    },
    {
      name: "io1",
      type: "bar",
      data: [2.0, 4.9, 7.0, 23.2],
    },
    {
      name: "st1",
      type: "bar",
      data: [2.6, 5.9, 9.0, 26.4],
    },
    {
      name: "standard",
      type: "bar",
      data: [2.6, 5.9, 9.0, 26.4],
    },
    {
      name: "unit cost (all)",
      type: "line",
      yAxisIndex: 0,
      data: [2.0, 2.2, 3.3, 4.5],
    },
  ],
  xAxisLabels: ["Dec 2023", "Jan 2024", "Feb 2024", "Mar 2024"],
};

export const kpiTableMonths = (months) => {
  const labels = [
    { offset: 2, label: "2 months ago" },
    { offset: 1, label: "1 month ago" },
    { offset: 0, label: "This month" },
  ];

  if (months === 4) {
    labels.unshift({ offset: 3, label: "3 months ago" });
  }

  return labels;
};

export const EBSSnapShotStorageSpendTableHeaders = [
  { id: "headers", label: "" },
  { id: "feb2024", label: "Feb 2024" },
  { id: "jan2024", label: "Jan 2024" },
  { id: "dec2023", label: "Dec 2023" },
];

export const EBSSnapShotStorageSpendTableData = [
  {
    headers: "% EBS Snapshot of EBS Storage",
    feb2024: "10%",
    jan2024: "10%",
    dec2023: "10%",
  },
  {
    headers: "EBS Storage Cost",
    feb2024: "$4,84,294",
    jan2024: "$4,14,294",
    dec2023: "$4,24,294",
  },
  {
    headers: "EBS Snapshot Cost",
    feb2024: "$4,84,294",
    jan2024: "$4,14,294",
    dec2023: "$4,24,294",
  },
  {
    headers: "% Snapshots >1yr ",
    feb2024: "0%",
    jan2024: "0%",
    dec2023: "0%",
  },
];

export const EBSSnapShotStorageSpendTableHeadersAdd = [
  { id: "service", label: "" },
  { id: "add", label: "" },
];

export const EBSSnapShotStorageSpendTableDataAdd = [10, 20, 20];

export const EBSSnapshotLookupTableData = [
  {
    resourceId: "arn:aws:ec2:us-east-1:+AJIsKmUBF4i:snapshot/Xi+PDZhIK6bn",
    startDate: "Nov 19, 2023",
    dec2023Cost: "$0.17",
    dec2023Usage: "3.35",
    jan2023Cost: "$0.40",
    jan2023Usage: "8.00",
    feb2023Cost: "$0.38",
    feb2023Usage: "7.58",
  },
  {
    resourceId: "arn:aws:ec2:us-east-1:+AJIsKmUBF4i:snapshot/Xi+PDZhIK6bn",
    startDate: "Nov 20, 2023",
    dec2023Cost: "$0.17",
    dec2023Usage: "3.35",
    jan2023Cost: "$0.40",
    jan2023Usage: "8.00",
    feb2023Cost: "$0.38",
    feb2023Usage: "7.58",
  },
  {
    resourceId: "arn:aws:ec2:us-east-1:+AJIsKmUBF4i:snapshot/Xi+PDZhIK6bn",
    startDate: "Nov 21, 2023",
    dec2023Cost: "$0.17",
    dec2023Usage: "3.35",
    jan2023Cost: "$0.40",
    jan2023Usage: "8.00",
    feb2023Cost: "$0.38",
    feb2023Usage: "7.58",
  },
  {
    resourceId: "arn:aws:ec2:us-east-1:+AJIsKmUBF4i:snapshot/Xi+PDZhIK6bn",
    startDate: "Nov 22, 2023",
    dec2023Cost: "$0.17",
    dec2023Usage: "3.35",
    jan2023Cost: "$0.40",
    jan2023Usage: "8.00",
    feb2023Cost: "$0.38",
    feb2023Usage: "7.58",
  },
  {
    resourceId: "arn:aws:ec2:us-east-1:+AJIsKmUBF4i:snapshot/Xi+PDZhIK6bn",
    startDate: "Nov 23, 2023",
    dec2023Cost: "$0.17",
    dec2023Usage: "3.35",
    jan2023Cost: "$0.40",
    jan2023Usage: "8.00",
    feb2023Cost: "$0.38",
    feb2023Usage: "7.58",
  },
  {
    resourceId: "arn:aws:ec2:us-east-1:+AJIsKmUBF4i:snapshot/Xi+PDZhIK6bn",
    startDate: "Nov 24, 2023",
    dec2023Cost: "$0.17",
    dec2023Usage: "3.35",
    jan2023Cost: "$0.40",
    jan2023Usage: "8.00",
    feb2023Cost: "$0.38",
    feb2023Usage: "7.58",
  },
  {
    resourceId: "arn:aws:ec2:us-east-1:+AJIsKmUBF4i:snapshot/Xi+PDZhIK6bn",
    startDate: "Nov 25, 2023",
    dec2023Cost: "$0.17",
    dec2023Usage: "3.35",
    jan2023Cost: "$0.40",
    jan2023Usage: "8.00",
    feb2023Cost: "$0.38",
    feb2023Usage: "7.58",
  },
  {
    resourceId: "Total",
    startDate: "",
    dec2023Cost: "$8.17k",
    dec2023Usage: "131.135.37",
    jan2023Cost: "$19.40k",
    jan2023Usage: "395,923.00",
    feb2023Cost: "$19.49k",
    feb2023Usage: "",
  },
];

export const ebsPrevMonthEBSBarData = [
  {
    usage_quanity: 200.0,
    name: "2023-11-20",
  },
  {
    usage_quanity: 300.0,
    name: "2023-11-22",
  },
  {
    usage_quanity: 100.0,
    name: "2023-11-24",
  },
  {
    usage_quanity: 250.0,
    name: "2023-11-26",
  },
  {
    usage_quanity: 150.0,
    name: "2023-11-28",
  },
  {
    usage_quanity: 350.0,
    name: "2023-11-30",
  },
  {
    usage_quanity: 550.0,
    name: "2023-12-02",
  },
  {
    usage_quanity: 450.0,
    name: "2023-12-04",
  },
  {
    usage_quanity: 500.0,
    name: "2023-12-06",
  },
  {
    usage_quanity: 400.0,
    name: "2023-12-08",
  },
  {
    usage_quanity: 700.0,
    name: "2023-12-10",
  },
  {
    usage_quanity: 650.0,
    name: "2023-12-12",
  },
  {
    usage_quanity: 600.0,
    name: "2023-12-14",
  },
  {
    usage_quanity: 750.0,
    name: "2023-12-16",
  },
  {
    usage_quanity: 800.0,
    name: "2023-12-18",
  },
  {
    usage_quanity: 1050.0,
    name: "2023-12-20",
  },
  {
    usage_quanity: 900.0,
    name: "2023-12-22",
  },
  {
    usage_quanity: 950.0,
    name: "2023-12-24",
  },
  {
    usage_quanity: 1000.0,
    name: "2023-12-26",
  },
  {
    usage_quanity: 850.0,
    name: "2023-12-28",
  },
  {
    usage_quanity: 1100.0,
    name: "2023-12-30",
  },
  {
    usage_quanity: 1150.0,
    name: "2024-01-01",
  },
  {
    usage_quanity: 1200.0,
    name: "2024-01-03",
  },
  {
    usage_quanity: 1250.0,
    name: "2024-01-05",
  },
  {
    usage_quanity: 1300.0,
    name: "2024-01-07",
  },
  {
    usage_quanity: 2200.0,
    name: "2024-01-09",
  },
  {
    usage_quanity: 1400.0,
    name: "2024-01-11",
  },
  {
    usage_quanity: 1450.0,
    name: "2024-01-13",
  },
  {
    usage_quanity: 1500.0,
    name: "2024-01-15",
  },
  {
    usage_quanity: 1550.0,
    name: "2024-01-17",
  },
  {
    usage_quanity: 1600.0,
    name: "2024-01-19",
  },
  {
    usage_quanity: 1650.0,
    name: "2024-01-21",
  },
  {
    usage_quanity: 1700.0,
    name: "2024-01-23",
  },
  {
    usage_quanity: 1750.0,
    name: "2024-01-25",
  },
  {
    usage_quanity: 1800.0,
    name: "2024-01-27",
  },
  {
    usage_quanity: 1850.0,
    name: "2024-01-29",
  },
  {
    usage_quanity: 1900.0,
    name: "2024-01-31",
  },
  {
    usage_quanity: 1950.0,
    name: "2024-02-02",
  },
  {
    usage_quanity: 2000.0,
    name: "2024-02-04",
  },
  {
    usage_quanity: 2050.0,
    name: "2024-02-06",
  },
  {
    usage_quanity: 2100.0,
    name: "2024-02-08",
  },
  {
    usage_quanity: 2600.0,
    name: "2024-02-10",
  },
  {
    usage_quanity: 1350.0,
    name: "2024-02-12",
  },
  {
    usage_quanity: 2250.0,
    name: "2024-02-14",
  },
  {
    usage_quanity: 2300.0,
    name: "2024-02-16",
  },
  {
    usage_quanity: 2350.0,
    name: "2024-02-18",
  },
  {
    usage_quanity: 2400.0,
    name: "2024-02-20",
  },
  {
    usage_quanity: 2450.0,
    name: "2024-02-22",
  },
  {
    usage_quanity: 2500.0,
    name: "2024-02-24",
  },
  {
    usage_quanity: 2550.0,
    name: "2024-02-26",
  },
  {
    usage_quanity: 2150.0,
    name: "2024-02-28",
  },
];

export const ebsBarData = [
  {
    percent: 93.33,
    name: "Dec 2023",
  },
  {
    percent: 37.13,
    name: "Jan 2024",
  },
  {
    percent: 65.89,
    name: "Feb 2024",
  },
];

export const RDSPotentialSavingsTableData = [
  {
    region: "eu-central-1",
    accountId: "Account QZd3",
    instanceFamily: "r6g",
    latestGraviton: "empty",
    databaseEngine: "Aurora PostgreSQL",
    deploymentMode: "Single-AZ",
    resourceId: "arn:aws:rds:eu-central",
    dec2023Cost: "$0.57",
    dec2023PotentialSavings: "0",
    jan2023Cost: "$0.67",
    jan2023PotentialSavings: "0",
    feb2023Cost: "$32.98",
    feb2023PotentialSavings: "0",
  },
  {
    region: "us-east-2",
    accountId: "Account XYZ456",
    instanceFamily: "m5",
    latestGraviton: "empty",
    databaseEngine: "MySQL",
    deploymentMode: "Multi-AZ",
    resourceId: "arn:aws:rds:us-east-2",
    dec2023Cost: "$0.60",
    dec2023PotentialSavings: "5",
    jan2023Cost: "$0.70",
    jan2023PotentialSavings: "7",
    feb2023Cost: "$34.50",
    feb2023PotentialSavings: "8",
  },
  {
    region: "ap-southeast-1",
    accountId: "Account PQR789",
    instanceFamily: "t3",
    latestGraviton: "empty",
    databaseEngine: "Oracle",
    deploymentMode: "Single-AZ",
    resourceId: "arn:aws:rds:ap-southeast-1",
    dec2023Cost: "$0.55",
    dec2023PotentialSavings: "4",
    jan2023Cost: "$0.65",
    jan2023PotentialSavings: "6",
    feb2023Cost: "$31.75",
    feb2023PotentialSavings: "7",
  },
  {
    region: "eu-west-1",
    accountId: "Account MNO123",
    instanceFamily: "r5",
    latestGraviton: "empty",
    databaseEngine: "SQL Server",
    deploymentMode: "Multi-AZ",
    resourceId: "arn:aws:rds:eu-west-1",
    dec2023Cost: "$0.70",
    dec2023PotentialSavings: "9",
    jan2023Cost: "$0.80",
    jan2023PotentialSavings: "10",
    feb2023Cost: "$36.20",
    feb2023PotentialSavings: "12",
  },
  {
    region: "ap-northeast-1",
    accountId: "Account STU789",
    instanceFamily: "m6g",
    latestGraviton: "empty",
    databaseEngine: "Aurora MySQL",
    deploymentMode: "Single-AZ",
    resourceId: "arn:aws:rds:ap-northeast-1",
    dec2023Cost: "$0.65",
    dec2023PotentialSavings: "6",
    jan2023Cost: "$0.75",
    jan2023PotentialSavings: "8",
    feb2023Cost: "$33.75",
    feb2023PotentialSavings: "9",
  },
  {
    region: "sa-east-1",
    accountId: "Account VWX123",
    instanceFamily: "r4",
    latestGraviton: "empty",
    databaseEngine: "PostgreSQL",
    deploymentMode: "Multi-AZ",
    resourceId: "arn:aws:rds:sa-east-1",
    dec2023Cost: "$0.55",
    dec2023PotentialSavings: "4",
    jan2023Cost: "$0.65",
    jan2023PotentialSavings: "5",
    feb2023Cost: "$31.50",
    feb2023PotentialSavings: "6",
  },
  {
    region: "us-west-1",
    accountId: "Account YZA123",
    instanceFamily: "t3a",
    latestGraviton: "empty",
    databaseEngine: "SQL Server",
    deploymentMode: "Single-AZ",
    resourceId: "arn:aws:rds:us-west-1",
    dec2023Cost: "$0.72",
    dec2023PotentialSavings: "10",
    jan2023Cost: "$0.82",
    jan2023PotentialSavings: "12",
    feb2023Cost: "$36.75",
    feb2023PotentialSavings: "15",
  },
];

export const RDSWordCloudGraphData = [
  { text: "Word1", weight: 20 },
  { text: "Word2", weight: 15 },
  { text: "Word3", weight: 25 },
  { text: "Word4", weight: 60 },
  { text: "Word5", weight: 55 },
  { text: "Word6", weight: 105 },
  { text: "Word1", weight: 20 },
  { text: "Word2", weight: 15 },
  { text: "Word3", weight: 25 },
  { text: "Word4", weight: 60 },
  { text: "Word5", weight: 55 },
  { text: "Word6", weight: 105 },
  { text: "Word1", weight: 20 },
  { text: "Word2", weight: 15 },
  { text: "Word3", weight: 25 },
  { text: "Word4", weight: 60 },
  { text: "Word5", weight: 55 },
  { text: "Word6", weight: 105 },
  { text: "Word1", weight: 20 },
  { text: "Word2", weight: 15 },
  { text: "Word3", weight: 25 },
  { text: "Word4", weight: 60 },
  { text: "Word5", weight: 55 },
  { text: "Word6", weight: 105 },
];

export const RDSPotentialSavingsTableHeaders = [
  {
    id: "region",
    label: "Region",
  },
  {
    id: "accountId",
    label: "Account Id",
  },
  {
    id: "instanceFamily",
    label: "Instance Family",
  },
  {
    id: "latestGraviton",
    label: "Latest Graviton",
  },
  {
    id: "databaseEngine",
    label: "Database Engine",
  },
  {
    id: "deploymentMode",
    label: "Deployment Mode",
  },
  {
    id: "resourceId",
    label: "Resource Id",
  },
  {
    id: "dec2023Cost",
    label: "Cost",
  },
  {
    id: "dec2023PotentialSavings",
    label: "RDS Graviton Potential Savings",
  },
  {
    id: "jan2023Cost",
    label: "Cost",
  },
  {
    id: "jan2023PotentialSavings",
    label: "RDS Graviton Potential Savings",
  },
  {
    id: "feb2023Cost",
    label: "Cost",
  },
  {
    id: "feb2023PotentialSavings",
    label: "RDS Graviton Potential Savings",
  },
];

export const EBSSnapshotLookupTableHeaders = [
  {
    id: "resourceId",
    label: "Resource Id",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
  {
    id: "dec2023Cost",
    label: "Cost",
  },
  {
    id: "dec2023Usage",
    label: "Usage",
  },
  {
    id: "jan2023Cost",
    label: "Cost",
  },
  {
    id: "jan2023Usage",
    label: "Usage",
  },
  {
    id: "feb2023Cost",
    label: "Cost",
  },
  {
    id: "feb2023Usage",
    label: "Usage",
  },
];

export const EBSVolumeLookupTableHeaders = [
  { id: "volumeId", label: "Volume Id" },
  { id: "volumeType", label: "Volume Type" },
  { id: "dec2023Cost", label: "Cost" },
  { id: "dec2023Size", label: "Size" },
  { id: "dec2023IOPs", label: "IOPs" },
  { id: "dec2023Throughput", label: "Throughput" },
  { id: "jan2024Cost", label: "Cost" },
  { id: "jan2024Size", label: "Size" },
  { id: "jan2024IOPs", label: "IOPs" },
  { id: "jan2024Throughput", label: "Throughput" },
  { id: "feb2023Cost", label: "Cost" },
  { id: "feb2023Size", label: "Size" },
  { id: "feb2023IOPs", label: "IOPs" },
  { id: "feb2023Throughput", label: "Throughput" },
  { id: "march2023Cost", label: "Cost" },
  { id: "march2023Size", label: "Size" },
  { id: "march2023IOPs", label: "IOPs" },
  { id: "march2023Throughput", label: "Throughput" },
];

export const EBSVolumeTableHeaders = [
  { id: "volumeId", label: "Volume Id" },
  { id: "groupBy", label: "Group By" },
  { id: "estimatedSavings", label: "Estimated Savings %" },
  { id: "potentialGp3Savings", label: "Potential Gp3 Savings" },
  { id: "gp2Cost", label: "gp2 Cost" },
  { id: "size", label: "Size (GB/MO)" },
  { id: "potentialAddedLoops", label: "Potential Added Loops" },
  { id: "potentialAddedThroughput", label: "Potential Added Throughput " },
];

export const EBSVolumeTableData = [
  {
    volumeId: "1YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "14.7%",
    potentialGp3Savings: "$13.97",
    gp2Cost: "$94.84",
    size: "948",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "125",
  },
  {
    volumeId: "2YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "12.3%",
    potentialGp3Savings: "$11.50",
    gp2Cost: "$82.10",
    size: "721",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "97",
  },
  {
    volumeId: "3YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "18.9%",
    potentialGp3Savings: "$17.82",
    gp2Cost: "$121.45",
    size: "1024",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "136",
  },
  {
    volumeId: "4YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "11.5%",
    potentialGp3Savings: "$10.80",
    gp2Cost: "$92.00",
    size: "512",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "68",
  },
  {
    volumeId: "5YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "9.8%",
    potentialGp3Savings: "$9.23",
    gp2Cost: "$81.43",
    size: "623",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "83",
  },
  {
    volumeId: "6YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "16.2%",
    potentialGp3Savings: "$15.25",
    gp2Cost: "$94.01",
    size: "819",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "109",
  },
  {
    volumeId: "7YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "13.6%",
    potentialGp3Savings: "$12.81",
    gp2Cost: "$94.12",
    size: "718",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "96",
  },
  {
    volumeId: "8YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "21.4%",
    potentialGp3Savings: "$20.17",
    gp2Cost: "$94.37",
    size: "1024",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "136",
  },
  {
    volumeId: "9YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "7.9%",
    potentialGp3Savings: "$7.45",
    gp2Cost: "$93.43",
    size: "419",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "56",
  },
  {
    volumeId: "10YODASDBVWRQ",
    groupBy: "All",
    estimatedSavings: "15.3%",
    potentialGp3Savings: "$14.44",
    gp2Cost: "$94.32",
    size: "921",
    potentialAddedLoops: "0",
    potentialAddedThroughput: "123",
  },
  {
    volumeId: "",
    groupBy: "",
    estimatedSavings: "18.3%",
    potentialGp3Savings: "$7.09K",
    gp2Cost: "$38.81K",
    size: "388,070",
    potentialAddedLoops: "65,040",
    potentialAddedThroughput: "9,000",
  },
];

export const ec2StackBarData = [
  {
    name: "Current Spend",
    currentName: "Total Budget Limit",
    budgetName: "Dec 2023",
    amount: 1053.57,
    currentSpend1: 73.57,
    currentSpend: 25999,
  },
  {
    name: "Current Spend",
    currentName: "Total Budget Limit",
    budgetName: "Jan 2024",
    currentSpend1: 73.57,
    amount: 524.47,
    currentSpend: 2500,
  },
  {
    name: "Current Spend",
    currentName: "Total Budget Limit",
    budgetName: "Feb 2024",
    currentSpend1: 73.57,
    amount: 3053.57,
    currentSpend: 25999,
  },
  {
    name: "Current Spend",
    currentName: "Total Budget Limit",
    budgetName: "March 2024",
    currentSpend1: 73.57,
    amount: 224.47,
    currentSpend: 2500,
  },
];

export const ec2JoinedBarData = {
  labels: ["Dec 2023", "Jan 2024", "Feb 2024", "Mar 2024"],
  data: [
    {
      name: "Amd Potential Savings",
      data: [150, 232, 201, 154, 190],
    },
    {
      name: "Graviton Potential Savings",
      data: [220, 182, 191, 234, 290],
    },
  ],
};

export const ec2GraphData = [
  {
    name: "AWS Lambda",
    AWS: 0,
    cloud: "AWS",
  },
  {
    name: "Amazon Simple Storage Service",
    AWS: 1.15,
    cloud: "AWS",
  },
  {
    name: "Amazon Elastic Load Balancing",
    AWS: 3.54,
    cloud: "AWS",
  },
  {
    name: "AWS CloudTrail",
    AWS: 2.54,
    cloud: "AWS",
  },
  {
    name: "Amazon DocumentDB (with MongoDB compatibility)",
    AWS: 0,
    cloud: "AWS",
  },
  {
    name: "Amazon Simple Notification Service",
    AWS: 0,
    cloud: "AWS",
  },
  {
    name: "AWS Glue",
    AWS: 0.2,
    cloud: "AWS",
  },
  {
    name: "Amazon Rekognition",
    AWS: 0,
    cloud: "AWS",
  },
  {
    name: "Amazon Virtual Private Cloud",
    AWS: 2.96,
    cloud: "AWS",
  },
  {
    name: "EC2 - Other",
    AWS: 18.85,
    cloud: "AWS",
  },
  {
    name: "Amazon Relational Database Service",
    AWS: 0.02,
    cloud: "AWS",
  },
  {
    name: "AmazonCloudWatch",
    AWS: 4.5,
    cloud: "AWS",
  },
  {
    name: "Amazon Elastic Compute Cloud - Compute",
    AWS: 31.52,
    cloud: "AWS",
  },
  {
    name: "Amazon EC2 Container Registry (ECR)",
    AWS: 0.02,
    cloud: "AWS",
  },
  {
    name: "AWS Key Management Service",
    AWS: 1.99,
    cloud: "AWS",
  },
  {
    name: "AWS Secrets Manager",
    AWS: 1.68,
    cloud: "AWS",
  },
  {
    name: "AWS Cost Explorer",
    AWS: 16.69,
    cloud: "AWS",
  },
  {
    name: "Amazon DynamoDB",
    AWS: 0,
    cloud: "AWS",
  },
];

export const ec2StackBarGraphData = {
  legends: ["Dec 2023", "Jan 2024", "Feb 2024"],
  graphData: [
    {
      name: "Dec 2023",
      data: [420, 320, 320, 250, 720],
    },
    {
      name: "Jan 2024",
      data: [120, 420, 120, 550, 520],
    },
    {
      name: "Feb 2024",
      data: [520, 620, 420, 750, 320],
    },
  ],
};

export const METRICS_SUMMERY_DATA = {
  defaultHeaders: [
    { id: "service", label: "" },
    { id: "add", label: "" },
  ],
  defaultData: [
    { id: 1, add: 23, service: "S3 Storage Usage (GB/Mo)" },
    { id: 2, add: 35, service: "S3 Storage Usage Change" },
    { id: 3, add: 54, service: "S3 Unit Cost (All)" },
  ],
  serviceApiHeaders: [
    { id: "service", label: "" },
    { id: "c1", label: "Dec 2023" },
    { id: "c2", label: "Jan 2024" },
    { id: "c3", label: "Feb 2024" },
  ],
  s3ApiData: [
    {
      id: 1,
      service: "S3 Storage Usage (GB/Mo)",
      c1: "72K",
      c2: "137K",
      c3: "142K",
      add: 23,
    },
    {
      id: 2,
      service: "S3 Storage Usage Change",
      c1: "52K",
      c2: "127K",
      c3: "141K",
      add: 35,
    },
    {
      id: 3,
      service: "S3 Unit Cost (All)",
      c1: "50K",
      c2: "137K",
      c3: "112K",
      add: 54,
    },
  ],
  SnapshotsApiData: [
    {
      id: 1,
      service: "Snapshot Cost Change",
      c1: "72K",
      c2: "137K",
      c3: "142K",
      add: 23,
    },
    {
      id: 2,
      service: "% Snapshots over 1yr Coverage",
      c1: "52K",
      c2: "127K",
      c3: "141K",
      add: 35,
    },
    {
      id: 3,
      service: "% Snapshot Archive Coverage",
      c1: "50K",
      c2: "137K",
      c3: "112K",
      add: 54,
    },
  ],
  ebsApiData: [
    {
      id: 1,
      service: "EBS Storage Usage Change (GB/Mo)",
      c1: "72K",
      c2: "137K",
      c3: "142K",
      add: 23,
    },
    {
      id: 2,
      service: "Unit Cost (All)",
      c1: "52K",
      c2: "127K",
      c3: "141K",
      add: 35,
    },
    {
      id: 3,
      service: "gp3 of gp Coverage",
      c1: "50K",
      c2: "137K",
      c3: "112K",
      add: 54,
    },
  ],
  ec2ApiData: [
    {
      id: 1,
      service: "EC2 Spend Trend",
      c1: "72K",
      c2: "137K",
      c3: "142K",
      add: 23,
    },
    {
      id: 2,
      service: "EC2 Usage Trend",
      c1: "52K",
      c2: "127K",
      c3: "141K",
      add: 35,
    },
    {
      id: 3,
      service: "Unit Cost (EC2)",
      c1: "50K",
      c2: "137K",
      c3: "112K",
      add: 54,
    },
  ],
  servicesGravitionApiData: [
    {
      id: 1,
      service: "ElastiCache Graviton Coverage",
      c1: "72K",
      c2: "137K",
      c3: "142K",
      add: 23,
    },
    {
      id: 2,
      service: "OpenSearch Graviton Coverage",
      c1: "52K",
      c2: "127K",
      c3: "141K",
      add: 35,
    },
    {
      id: 3,
      service: "Lambda Graviton Coverage",
      c1: "50K",
      c2: "137K",
      c3: "112K",
      add: 54,
    },
  ],
  rdsApiData: [
    {
      id: 1,
      service: "RDS Oracle Coverage",
      c1: "72K",
      c2: "137K",
      c3: "142K",
      add: 23,
    },
    {
      id: 2,
      service: "RDS SQL Server Coverage",
      c1: "52K",
      c2: "127K",
      c3: "141K",
      add: 35,
    },
    {
      id: 3,
      service: "RDS Open Source Engines Coverage",
      c1: "50K",
      c2: "137K",
      c3: "112K",
      add: 54,
    },
  ],
  demandCoverageApiData: [
    {
      id: 1,
      service: "Compute OD Coverage",
      c1: "72K",
      c2: "137K",
      c3: "142K",
      add: 23,
    },
    {
      id: 2,
      service: "Sagemaker OD Coverage",
      c1: "52K",
      c2: "127K",
      c3: "141K",
      add: 35,
    },
    {
      id: 3,
      service: "RDS OD Coverage",
      c1: "50K",
      c2: "137K",
      c3: "112K",
      add: 54,
    },
  ],
};

export const s3BarData = [
  {
    Other: 42.63,
    name: "S3 all storage cost",
  },
];

export const s3TableHeaders = [
  { id: "resourceId", label: "resource_id" },
  { id: "groupBy", label: "Group By" },
  { id: "lastRequest", label: "Last Request" },
  { id: "s3Analytics", label: "S3 Analytics" },
  { id: "s3Inventory", label: "S3 Inventory" },
  { id: "usage", label: "Usage" },
  { id: "cost", label: "Cost" },
  { id: "psToSia", label: "Potential Savings to Move to SIA  " },
  { id: "estimatedCost", label: "Estimated Cost since Last Request" },
];

export const s3TableData = [
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "vo4bFEazDhzU",
    groupBy: "All",
    lastRequest: "null",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    usage: "0",
    cost: "$0.00",
    psToSia: "$0.00",
    estimatedCost: "$0.00",
  },
  {
    resourceId: "",
    groupBy: "",
    lastRequest: "",
    s3Analytics: "",
    s3Inventory: "",
    usage: "18K",
    cost: "$449.70",
    psToSia: "$134.91",
    estimatedCost: "$2.44",
  },
];

export const multiLineChartData = [
  {
    name: "Dec 2023",
    data: [320, 1200],
    serviceName: ["S3 Unit Cost(GB stored)", "S3 Unit Cost(All)"],
  },
  {
    name: "Jan 2024",
    data: [120, 920],
    serviceName: ["S3 Unit Cost(GB stored)", "S3 Unit Cost(All)"],
  },
  {
    name: "Feb 2024",
    data: [420, 1500],
    serviceName: ["S3 Unit Cost(GB stored)", "S3 Unit Cost(All)"],
  },
];

export const s3LookupTableHaders = [
  { id: "resourceId", label: "resource_id" },
  { id: "groupBy", label: "Group By" },
  { id: "lastRequest", label: "Last Request" },
  { id: "s3Analytics", label: "S3 Analytics" },
  { id: "s3Inventory", label: "S3 Inventory" },
  { id: "s3Standard", label: "S3 Standard Only" },
  { id: "s3Int", label: "S3 INT" },
  { id: "s3Archive", label: "S3 Archive" },
  { id: "dec2023StorageCost", label: "Storage Cost" },
  { id: "dec2023Usage", label: "Usage" },
  { id: "dec2023StandardCoverage", label: "S3 Standard Coverage" },
  { id: "jan2024StorageCost", label: "Storage Cost" },
  { id: "jan2024Usage", label: "Usage" },
  { id: "jan2024StandardCoverage", label: "S3 Standard Coverage" },
  { id: "feb2024StorageCost", label: "Storage Cost" },
  { id: "feb2024Usage", label: "Usage" },
  { id: "feb2024StandardCoverage", label: "S3 Standard Coverage" },
  { id: "march2024StorageCost", label: "Storage Cost" },
  { id: "march2024Usage", label: "Usage" },
  { id: "march2024StandardCoverage", label: "S3 Standard Coverage" },
];

export const s3LookupTableData = [
  {
    resourceId: "YrxnQK8Yn93a",
    groupBy: "All",
    lastRequest: "Mar 17, 2024",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    s3Standard: "No",
    s3Int: "in use",
    s3Archive: "not in use",
    dec2023StorageCost: "$700.00",
    dec2023Usage: "903",
    dec2023StandardCoverage: "9,032",
    jan2024StorageCost: "$532.00",
    jan2024Usage: "323",
    jan2024StandardCoverage: "3,123",
    feb2024StorageCost: "$675.00",
    feb2024Usage: "532",
    feb2024StandardCoverage: "4,123",
    march2024StorageCost: "$675.00",
    march2024Usage: "532",
    march2024StandardCoverage: "4,123",
  },
  {
    resourceId: "YrxnQK8Yn93a",
    groupBy: "All",
    lastRequest: "Mar 17, 2024",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    s3Standard: "No",
    s3Int: "in use",
    s3Archive: "not in use",
    dec2023StorageCost: "$700.00",
    dec2023Usage: "903",
    dec2023StandardCoverage: "9,032",
    jan2024StorageCost: "$532.00",
    jan2024Usage: "323",
    jan2024StandardCoverage: "3,123",
    feb2024StorageCost: "$675.00",
    feb2024Usage: "532",
    feb2024StandardCoverage: "4,123",
    march2024StorageCost: "$675.00",
    march2024Usage: "532",
    march2024StandardCoverage: "4,123",
  },
  {
    resourceId: "YrxnQK8Yn93a",
    groupBy: "All",
    lastRequest: "Mar 17, 2024",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    s3Standard: "No",
    s3Int: "in use",
    s3Archive: "not in use",
    dec2023StorageCost: "$700.00",
    dec2023Usage: "903",
    dec2023StandardCoverage: "9,032",
    jan2024StorageCost: "$532.00",
    jan2024Usage: "323",
    jan2024StandardCoverage: "3,123",
    feb2024StorageCost: "$675.00",
    feb2024Usage: "532",
    feb2024StandardCoverage: "4,123",
    march2024StorageCost: "$675.00",
    march2024Usage: "532",
    march2024StandardCoverage: "4,123",
  },
  {
    resourceId: "YrxnQK8Yn93a",
    groupBy: "All",
    lastRequest: "Mar 17, 2024",
    s3Analytics: "not in use",
    s3Inventory: "not in use",
    s3Standard: "No",
    s3Int: "in use",
    s3Archive: "not in use",
    dec2023StorageCost: "$700.00",
    dec2023Usage: "903",
    dec2023StandardCoverage: "9,032",
    jan2024StorageCost: "$532.00",
    jan2024Usage: "323",
    jan2024StandardCoverage: "3,123",
    feb2024StorageCost: "$675.00",
    feb2024Usage: "532",
    feb2024StandardCoverage: "4,123",
    march2024StorageCost: "$675.00",
    march2024Usage: "532",
    march2024StandardCoverage: "4,123",
  },
  {
    resourceId: "Total",
    groupBy: "",
    lastRequest: "",
    s3Analytics: "",
    s3Inventory: "",
    s3Standard: "",
    s3Int: "",
    s3Archive: "",
    dec2023StorageCost: "$7000.00",
    dec2023Usage: "9030",
    dec2023StandardCoverage: "9,0320",
    jan2024StorageCost: "$5322.00",
    jan2024Usage: "3233",
    jan2024StandardCoverage: "3,1233",
    feb2024StorageCost: "$6754.00",
    feb2024Usage: "5323",
    feb2024StandardCoverage: "4,1233",
    march2024StorageCost: "$6753.00",
    march2024Usage: "5322",
    march2024StandardCoverage: "4,1232",
  },
];

export const commitOnDemanKpiRange = [
  {
    id: "computeOdGoal",
    title: "Compute OD Goal (%)",
  },
  {
    id: "sagemakerOdGoal",
    title: "Sagemaker OD Goal (%)",
  },
  {
    id: "rdsOdGoal",
    title: "RDS OD Goal (%)",
  },
  {
    id: "elastiCacheOdGoal",
    title: "ElastiCache OD Goal (%)",
  },
  {
    id: "openSearchOdGoal",
    title: "OpenSearch OD Goal (%)",
  },
  {
    id: "redshiftOdGoal",
    title: "Redshift OD Goal (%)",
  },
  {
    id: "dynamoDBOdGoal",
    title: "DynamoDB OD Goal (%)",
  },
];

export const optimizeKpiRange = [
  {
    id: "ec2PreviousGenerationGoal",
    title: "EC2 Previous Generation Goal (%)",
  },
  {
    id: "ec2SpotGoal",
    title: "EC2 Spot Goal (%)",
  },
  {
    id: "ec2GravitonGoal",
    title: "EC2 Graviton Goal (%)",
  },
  {
    id: "ec2AmdGoal",
    title: "EC2 AMD Goal (%)",
  },
  {
    id: "ebsGp3Goal",
    title: "EBS gp3 of gp Goal(%)",
  },
  {
    id: "ebsSnapshotOver1yrGoal",
    title: "EBS Snapshot over 1yr Goal (%)",
  },
  {
    id: "s3StandardStorageGoal",
    title: "S3 Standard Storage Goal (%)",
  },
  {
    id: "rdsGravitonGoal",
    title: "RDS Graviton Goal(%)",
  },
  {
    id: "rdsOpenSourceEnginesGoal",
    title: "RDS Open Source Engines Goal (%)",
  },
  {
    id: "elastiCacheGravitonGoal",
    title: "ElastiCache Graviton Goal(%)",
  },
  {
    id: "openSearchGravitonGoal",
    title: "OpenSearch Graviton Goal(%)",
  },
  {
    id: "lambdaGravitonGoal",
    title: "Lambda Graviton Goal(%)",
  },
];

export const rdsMapChartData = {
  data: [
    {
      region: "eu-west-3",
      totalSpend: 0.01,
    },
    {
      region: "eu-north-1",
      totalSpend: 0.01,
    },
    {
      region: "eu-west-2",
      totalSpend: 0.01,
    },
    {
      region: "eu-west-1",
      totalSpend: 0.01,
    },
    {
      region: "ap-northeast-3",
      totalSpend: 0.01,
    },
    {
      region: "ap-northeast-2",
      totalSpend: 0.01,
    },
    {
      region: "ap-northeast-1",
      totalSpend: 0.01,
    },
    {
      region: "sa-east-1",
      totalSpend: 0.01,
    },
    {
      region: "ca-central-1",
      totalSpend: 0.01,
    },
    {
      region: "ap-southeast-2",
      totalSpend: 0.01,
    },
    {
      region: "eu-central-1",
      totalSpend: 0.01,
    },
    {
      region: "us-east-2",
      totalSpend: 0.01,
    },
    {
      region: "us-west-1",
      totalSpend: 0.01,
    },
    {
      region: "us-west-2",
      totalSpend: 0.02,
    },
    {
      region: "ap-southeast-1",
      totalSpend: 0.06,
    },
    {
      region: "us-east-1",
      totalSpend: 22.86,
    },
    {
      region: "ap-south-1",
      totalSpend: 69.81,
    },
  ],
};

export const kpiTrackerEstimatedAdditionalMoneyTableDataRight = [
  {
    category: "Spend",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "Total Savings at Goal",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "EC2 Prev Generation",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "EC2 Spot",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "EC2 Graviton",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "EC2 AMD",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "EC2 EBS gp3 of gp",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "EC2 EBS Snapshots >1yr",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "S3 Standard",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "RDS Graviton",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "RDS Open Source Engines Coverage",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "ElastiCache Graviton",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "OpenSearch Graviton",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "Lambda Graviton",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "Compute Commit",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "SageMaker Commit",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "RDS Commit",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "ElastiCache Commit",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "OpenSearch Commit",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "Redshift Commit",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "DynamoDB Commit",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
];

export const kpiTrackerEstimatedAdditionalMoneyTableDataLeft = [
  {
    category: "Spend",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "Total Savings at Goal",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "EC2 Prev Generation",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "EC2 Spot",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "EC2 Graviton",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "EC2 AMD ",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "EC2 EBS gp3 of gp ",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "EC2 EBS Snapshots >1yr",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "S3 Standard",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "RDS Graviton",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "RDS Open Source Engines Coverage",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "ElastiCache Graviton",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "OpenSearch Graviton",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "Lambda Graviton",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "Compute Commit",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "SageMaker Commit",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "RDS Commit",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "ElastiCache Commit",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "OpenSearch Commit",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "Redshift Commit",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "DynamoDB Commit",
    all: "$1.7M",
    allVal: "$1.7M",
  },
];

export const kpiTrackerGoalTrackingRightTableData = [
  {
    category: "Spend",
    dec2023: "$298.5K",
    jan2024: "$728.2K",
    feb2024: "$639.2K",
    total: "$1.7M",
  },
  {
    category: "EC2 Prev Generation Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "EC2 Spot Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "EC2 Graviton Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "EC2 AND Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "EBS gp3 of gp Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "EBS Snapshot >1yr Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "S3 Standard Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "RDS Graviton Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "RDS Open Source Engines Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "ElastiCache Graviton Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "OpenSearch Graviton Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "Lambda Graviton Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "Compute OD Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "Sagemaker OD Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "RDS OD Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "ElastiCache OD Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "OpenSearch OD Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "Redshift OD Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
  {
    category: "DynamoDB OD Goal",
    dec2023: "56%",
    jan2024: "57%",
    feb2024: "53%",
    total: "55%",
  },
];

export const kpiTrackerGoalTrackingLeftTableData = [
  {
    category: "Spend",
    all: "$1.7M",
    allVal: "$1.7M",
  },
  {
    category: "EC2 Prev Generation Goal",
    all: "≤10%",
    allVal: "10%",
  },
  {
    category: "EC2 Spot Goal",
    allVal: "10%",
    all: "≥10%",
  },
  {
    category: "EC2 Graviton Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "EC2 AMD Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "EBS gp3 of gp Goal ",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "EBS Snapshot >1yr Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "S3 Standard Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "RDS Graviton Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "RDS Open Source Engines Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "ElastiCache Graviton Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "OpenSearch Graviton Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "Lambda Graviton Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "Compute OD Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "Sagemaker OD Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "RDS OD Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "ElastiCache OD Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "OpenSearch OD Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "Redshift OD Goal",
    all: "≥20%",
    allVal: "20%",
  },
  {
    category: "DynamoDB OD Goal",
    all: "≥20%",
    allVal: "20%",
  },
];

export const kpiTrackerGoalTrackingRightTableHeaders = [
  {
    id: "category",
    label: "",
  },
  {
    id: "dec2023",
    label: "Dec 2023",
  },
  {
    id: "jan2024",
    label: "Jan 2024",
  },
  {
    id: "feb2024",
    label: "Feb 2024",
  },
];

export const kpiTrackerGoalTrackingLeftTableHeaders = [
  {
    id: "category",
    label: "",
  },
  {
    id: "all",
    label: "All",
  },
];

export const kpiTrackerGoalTrackingRightTableHeadersAdd = [
  {
    id: "category",
    label: "",
  },
  {
    id: "total",
    label: "Total",
  },
];

export const kpiTrackerGoalTrackingLeftTableHeadersAdd = [
  {
    id: "category",
    label: "",
  },
  {
    id: "allVal",
    label: "",
  },
];

export const OtherGravitionDropData = [
  "ap-east-1",
  "ap-northeast-1",
  "ap-south-1",
  "ca-central-1",
];

export const OtherGravitionServiceDropData = [
  "ElastiCache",
  "Lambda",
  "OpenSearch",
];

export const otherGravitionTableHeaders = [
  { id: "groupBy", label: "Group by" },
  { id: "productCode", label: "product_code" },
  { id: "region", label: "region" },
  { id: "instanceFamily", label: "Instance Family" },
  { id: "latestGraviton", label: "latest_graviton" },
  { id: "instanceType", label: "instance_type" },
  { id: "resourceId", label: "resource_id" },
  { id: "dec2023Cost", label: "Storage Cost" },
  { id: "dec2023GPSavings", label: "Usage" },
  { id: "jan2024Cost", label: "Storage Cost" },
  { id: "jan2024GPSavings", label: "Usage" },
  { id: "feb2024Cost", label: "Storage Cost" },
  { id: "feb2024GPSavings", label: "Usage" },
  { id: "march2024Cost", label: "Storage Cost" },
  { id: "march2024GPSavings", label: "Usage" },
];

export const otherGravitionTableData = [
  {
    groupBy: "",
    productCode: "",
    region: "",
    instanceFamily: "",
    latestGraviton: "",
    instanceType: "",
    resourceId: "arn:aws:lambda:eu-",
    dec2023Cost: "$0.00",
    dec2023GPSavings: "$0.00",
    jan2024Cost: "",
    jan2024GPSavings: "",
    feb2024Cost: "",
    feb2024GPSavings: "",
    march2024Cost: "",
    march2024GPSavings: "",
  },
  {
    groupBy: "",
    productCode: "",
    region: "",
    instanceFamily: "",
    latestGraviton: "",
    instanceType: "",
    resourceId: "arn:aws:lambda:eu-",
    dec2023Cost: "",
    dec2023GPSavings: "",
    jan2024Cost: "$0.00",
    jan2024GPSavings: "",
    feb2024Cost: "",
    feb2024GPSavings: "$0.00",
    march2024Cost: "",
    march2024GPSavings: "",
  },
  {
    groupBy: "",
    productCode: "",
    region: "",
    instanceFamily: "",
    latestGraviton: "",
    instanceType: "",
    resourceId: "arn:aws:lambda:eu-",
    dec2023Cost: "",
    dec2023GPSavings: "$0.00",
    jan2024Cost: "",
    jan2024GPSavings: "$0.00",
    feb2024Cost: "",
    feb2024GPSavings: "",
    march2024Cost: "",
    march2024GPSavings: "",
  },
];

export const commitOptimizationTableHeaders = [
  { id: "commitService", label: "Commit Service" },
  { id: "commitSummary", label: "Commit Summary" },
  { id: "groupBy", label: "Group by" },
  { id: "dec2023OnDemand", label: "OnDemand" },
  { id: "dec2023Reserved", label: "Reserved" },
  { id: "dec2023SavingsPlan", label: "SavingsPlan" },
  { id: "dec2023Spot", label: "Spot" },
  { id: "jan2024OnDemand", label: "OnDemand" },
  { id: "jan2024Reserved", label: "Reserved" },
  { id: "jan2024SavingsPlan", label: "SavingsPlan" },
  { id: "jan2024Spot", label: "Spot" },
  { id: "feb2024OnDemand", label: "OnDemand" },
  { id: "feb2024Reserved", label: "Reserved" },
  { id: "feb2024SavingsPlan", label: "SavingsPlan" },
  { id: "feb2024Spot", label: "Spot" },
  { id: "mar2024OnDemand", label: "OnDemand" },
  { id: "mar2024Reserved", label: "Reserved" },
  { id: "mar2024SavingsPlan", label: "SavingsPlan" },
  { id: "mar2024Spot", label: "Spot" },
];

export const commitOptimizationTableData = [
  {
    commitService: "OpenSearch",
    commitSummary: "AmazonES | eu-central-1",
    groupBy: "All",
    dec2023OnDemand: "2.15K",
    dec2023Reserved: "",
    dec2023SavingsPlan: "",
    dec2023Spot: "",
    jan2024OnDemand: "3.15K",
    jan2024Reserved: "",
    jan2024SavingsPlan: "",
    jan2024Spot: "",
    feb2024OnDemand: "5.15K",
    feb2024Reserved: "",
    feb2024SavingsPlan: "",
    feb2024Spot: "",
    mar2024OnDemand: "6.15K",
    mar2024Reserved: "",
    mar2024SavingsPlan: "",
    mar2024Spot: "",
  },
  {
    commitService: "RDS",
    commitSummary: "AmazonES | eu-central-1",
    groupBy: "All",
    dec2023OnDemand: "2.15K",
    dec2023Reserved: "",
    dec2023SavingsPlan: "",
    dec2023Spot: "",
    jan2024OnDemand: "3.15K",
    jan2024Reserved: "",
    jan2024SavingsPlan: "",
    jan2024Spot: "",
    feb2024OnDemand: "5.15K",
    feb2024Reserved: "",
    feb2024SavingsPlan: "",
    feb2024Spot: "",
    mar2024OnDemand: "6.15K",
    mar2024Reserved: "",
    mar2024SavingsPlan: "",
    mar2024Spot: "",
  },
  {
    commitService: "ElastiCache",
    commitSummary: "AmazonES | eu-central-1",
    groupBy: "All",
    dec2023OnDemand: "2.15K",
    dec2023Reserved: "",
    dec2023SavingsPlan: "",
    dec2023Spot: "",
    jan2024OnDemand: "3.15K",
    jan2024Reserved: "",
    jan2024SavingsPlan: "",
    jan2024Spot: "",
    feb2024OnDemand: "5.15K",
    feb2024Reserved: "",
    feb2024SavingsPlan: "",
    feb2024Spot: "",
    mar2024OnDemand: "6.15K",
    mar2024Reserved: "",
    mar2024SavingsPlan: "",
    mar2024Spot: "",
  },
];

export const commitOptimizationDropData = [
  "Compute",
  "DynamoDB",
  "ElastiCache",
  "Machine Learning",
  "OpenSearch",
  "RDS",
  "Redshift",
];

export const commitOptimizationRadioData = {
  showValues: [
    { value: "Cost", label: "Cost" },
    { value: "Usage", label: "Usage" },
  ],
  computeSavingsPlan: [
    { value: "Compute SP", label: "Compute SP" },
    { value: "EC2 Instance SP", label: "EC2 Instance SP" },
  ],
};
