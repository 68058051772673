import React from "react";
import "../../../style/Infra/storage.scss";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import SubHeader from "../../header/SubHeader";
import Header from "../../header/Header";

export default function UnusedResource() {
  const SUB_NAV_ITEMS = [
    {
      Title: "RDS",
      path: "/unused-resource/rds",
    },
    {
      Title: "S3",
      path: "/unused-resource/s3",
    },
    {
      Title: "EC2",
      path: "/unused-resource/ec2",
    },
  ];
  return (
    <>
      <Header />
      <SubHeader SUB_NAV_ITEMS={SUB_NAV_ITEMS} />
      <Outlet />
    </>
  );
}
