import React, { Suspense } from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import BaseHelmet from "./components/BaseHelmet/index";
import Header from "./components/header";
import Loader from "./components/Loader";
import { ROUTE_PATH } from "./constants/Routes";
import Login from "./pages/Billing/Login";
import Compute from "./pages/Infra/Compute/Compute";
import Images from "./pages/Infra/Compute/Images/Images";
import Servers from "./pages/Infra/Compute/Servers/Servers";
import Inventory from "./pages/Infra/inventory/Inventory";
import Main from "./pages/Infra/Main";
import S3 from "./pages/Infra/Storage/s3/s3";
import Storage from "./pages/Infra/Storage/Storage";
import Volumes from "./pages/Infra/Storage/volumes/volumes";
import Users from "./pages/Users";
import PrivateRoute from "./PrivateRoute";

import ApplicationThemeProvider from "./components/ApplicationThemeProvider";
import CxoDashboard from "./pages/CXO/hdbfs";
import NoteBookInstance from "./pages/Infra/Compute/NoteBooksInstance/NoteBookInstance";
import ServerGroupId from "./pages/Infra/Compute/ServerGroups/ServerGroupId/ServerGroupId";
import ServerGroupsName from "./pages/Infra/Compute/ServerGroups/ServerGroupId/ServerGroupName";
import ServerGroups from "./pages/Infra/Compute/ServerGroups/ServerGroups";
import ServerDeatils from "./pages/Infra/Compute/Servers/ServerDeatils/ServerDeatils";
import WorkSpace from "./pages/Infra/Compute/WorkSpace/WorkSpace";
import Snapshots from "./pages/Infra/Storage/snapshots/snapshots";
import UnusedResourceEC2 from "./pages/Infra/UnUsedResources/ec2/ec2";
import UnusedResourceRDS from "./pages/Infra/UnUsedResources/rds/rds";
import UnusedResourceS3 from "./pages/Infra/UnUsedResources/s3/s3";
import UnusedResource from "./pages/Infra/UnUsedResources/UnUsedResources";

// importing lazy-loaded components
const OptimizationUsageSummary = React.lazy(() =>
  import("./pages/Billing/OptimizationUsageSummary")
);
const TicketDetails = React.lazy(() =>
  import("./pages/ITSM/TicketDetails/index")
);
const WorkflowModule = React.lazy(() =>
  import("./pages/ITSM/WorkflowModule/index")
);
const WorkflowListingModule = React.lazy(() =>
  import("./pages/ITSM/WorkflowListing/index")
);
const Posture = React.lazy(() => import("./pages/Billing/Posture/index"));
const BillingBudget = React.lazy(() => import("./pages/Billing/Budget/index"));
const CostAnomoly = React.lazy(() =>
  import("./pages/Billing/CostAnomoly/index")
);
const CurrentReservationInner = React.lazy(() =>
  import("./pages/Billing/OptimizeRate/CuurentReservationInner")
);
const CostTrends = React.lazy(() =>
  import("./pages/Billing/Posture/Action/costTrends")
);
const CreateBudget = React.lazy(() =>
  import("./components/Budget/CreateBudget/index")
);
const MarkupAndDiscount = React.lazy(() =>
  import("./pages/Billing/MarkupAndDiscount/index")
);
const OptimizeUsage = React.lazy(() =>
  import("./pages/Billing/OptimizeUsage/index")
);
const OptimizeAction = React.lazy(() =>
  import("./pages/Billing/OptimizeAction/index")
);
const OptimizeActionSumary = React.lazy(() =>
  import("./pages/Billing/OptimizationActionSummary/index")
);
const OptimizeRate = React.lazy(() =>
  import("./pages/Billing/OptimizeRate/index")
);
const Dashboard = React.lazy(() => import("./pages/Billing/Dashboard"));
const AnamolyDashboard = React.lazy(() =>
  import("./pages/Billing/AnamolyDash/index")
);
const BudgetAlertsAndInsights = React.lazy(() =>
  import("./pages/Billing/BudgetAlertsAndInsights/index")
);
const OptimizeUsageManagment = React.lazy(() =>
  import("./pages/Billing/OptmizeUsageManagment/index")
);
const KPIDashboard = React.lazy(() => import("./pages/Billing/KPIDashboard"));
const CostAnomolyDashboard = React.lazy(() =>
  import("./pages/Billing/CostAnamolyDash/index")
);
const CostContainerDashboard = React.lazy(() =>
  import("./pages/Billing/CostContainerDashboard/index")
);
const BudgetManagment = React.lazy(() =>
  import("./pages/Billing/BudgetManagment/index")
);

const ClmBilling = React.lazy(() => import("./pages/CLM/Billing/index"));

const ClmResourceBilling = React.lazy(() =>
  import("./pages/CLM/ResourceBilling/index")
);

const ClmProjectBilling = React.lazy(() =>
  import("./pages/CLM/ProjectBilling/index")
);

const ClmApplicationBilling = React.lazy(() =>
  import("./pages/CLM/ApplicationBilling/index")
);

const RoutesWrapper = ({
  isSmallScreenSidebarOpen,
  toggleSmallScreenSidebar,
}) => {
  const { pathname } = useLocation();

  return (
    <div className="appContainer w-100">
      <div>
        {pathname.includes(ROUTE_PATH?.LOGIN) ? null : (
          <Header
            toggleSmallScreenSidebar={toggleSmallScreenSidebar}
            isSmallScreenSidebarOpen={isSmallScreenSidebarOpen}
          />
        )}
        <div
          className={
            pathname.includes(ROUTE_PATH?.CXO?.dashboard)
              ? "AppCXO contentWrapper"
              : pathname.includes(ROUTE_PATH?.LOGIN)
              ? "AppLogin contentWrapper"
              : "App contentWrapper"
          }
        >
          <Suspense fallback={<Loader />}>
            <BaseHelmet />
            <ApplicationThemeProvider />
            <Routes>
              {/* Public Route for Login */}
              <Route path={ROUTE_PATH.LOGIN} element={<Login />} />

              {/* ITSM Routes */}
              <Route
                path={ROUTE_PATH.ITSM.workFlowModule}
                element={
                  <PrivateRoute>
                    <WorkflowModule />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.ITSM.workFlowModuleListing}
                element={
                  <PrivateRoute>
                    <WorkflowListingModule />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.ITSM.TicketDetails}
                element={
                  <PrivateRoute>
                    <TicketDetails />
                  </PrivateRoute>
                }
              ></Route>

              {/* Infrastructure Routes */}
              <Route
                path="/inventory"
                element={
                  <PrivateRoute>
                    <Inventory />
                  </PrivateRoute>
                }
              />
              <Route
                path="/compute"
                element={
                  <PrivateRoute>
                    <Compute />
                  </PrivateRoute>
                }
              >
                {/* Nested Routes for Compute */}
                <Route
                  path="servers"
                  element={
                    <PrivateRoute>
                      <Servers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="servers/:slugid/"
                  element={
                    <PrivateRoute>
                      <ServerDeatils />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="images"
                  element={
                    <PrivateRoute>
                      <Images />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="servergroup"
                  element={
                    <PrivateRoute>
                      <ServerGroups />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="servergroup/name/:id"
                  element={
                    <PrivateRoute>
                      <ServerGroupsName />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="servergroup/:slugid"
                  element={
                    <PrivateRoute>
                      <ServerGroupId />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/compute/workspace"
                  element={
                    <PrivateRoute>
                      <WorkSpace />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="notebookinstance"
                  element={
                    <PrivateRoute>
                      <NoteBookInstance />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/compute"
                  exact
                  element={<Navigate replace to="/compute/servers" />}
                />
              </Route>

              {/* Unused Resources Routes */}
              <Route
                path="/unused-resource"
                element={
                  <PrivateRoute>
                    <UnusedResource />
                  </PrivateRoute>
                }
              >
                <Route
                  path="/unused-resource/rds"
                  element={
                    <PrivateRoute>
                      <UnusedResourceRDS />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/unused-resource/s3"
                  element={
                    <PrivateRoute>
                      <UnusedResourceS3 />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/unused-resource/ec2"
                  element={
                    <PrivateRoute>
                      <UnusedResourceEC2 />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/unused-resource"
                  exact
                  element={<Navigate replace to="/unused-resource/rds" />}
                />
              </Route>

              {/* Storage Routes */}
              <Route
                path="/storage"
                element={
                  <PrivateRoute>
                    <Storage />
                  </PrivateRoute>
                }
              >
                <Route
                  path="/storage/volumes"
                  element={
                    <PrivateRoute>
                      <Volumes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/storage/s3"
                  element={
                    <PrivateRoute>
                      <S3 />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/storage/snapshots"
                  element={
                    <PrivateRoute>
                      <Snapshots />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/storage"
                  exact
                  element={<Navigate replace to="/storage/volumes" />}
                />
              </Route>

              {/* Other Infrastructure Routes */}
              <Route
                path={"/infra"}
                element={
                  <PrivateRoute>
                    <Main />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/inventory"
                element={
                  <PrivateRoute>
                    <Inventory />
                  </PrivateRoute>
                }
              />

              {/* Billing Routes */}
              <Route
                path={ROUTE_PATH.BILLING.COSTCONTAINER_DASHBOARD}
                element={
                  <PrivateRoute>
                    <CostContainerDashboard />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.CXO.dashboard}
                element={
                  <PrivateRoute>
                    <CxoDashboard />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.COSTCONTAINER_DASHBOARD}
                element={
                  <PrivateRoute>
                    <CostContainerDashboard />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.CURRENT_RESERVATION}
                element={
                  <PrivateRoute>
                    <CurrentReservationInner />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.CREATE_BUDGET}
                element={
                  <PrivateRoute>
                    <CreateBudget />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.UPDATE_BUDGET}
                element={
                  <PrivateRoute>
                    <CreateBudget />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.COST_TRENDS}
                element={
                  <PrivateRoute>
                    <CostTrends />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.DASHBOARD}
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.KPI_DASHBOARD}
                element={
                  <PrivateRoute>
                    <KPIDashboard />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.POSTURE}
                element={
                  <PrivateRoute>
                    <Posture />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.COST_TRENDS}
                element={
                  <PrivateRoute>
                    <CostTrends />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.BUDGET}
                element={
                  <PrivateRoute>
                    <BillingBudget />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.COST_ANOMOLY}
                element={
                  <PrivateRoute>
                    <CostAnomoly />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.MARKUP_AND_DISCOUNTS}
                element={
                  <PrivateRoute>
                    <MarkupAndDiscount />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_USAGE}
                element={
                  <PrivateRoute>
                    <OptimizeUsage />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_RATE}
                element={
                  <PrivateRoute>
                    <OptimizeRate />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.ANOMOLY_DASHBOARD}
                element={
                  <PrivateRoute>
                    <CostAnomolyDashboard />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.ALERTS_AND_INSIGHTS}
                element={
                  <PrivateRoute>
                    <BudgetAlertsAndInsights />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_USAGE_MANAGMENT}
                element={
                  <PrivateRoute>
                    <OptimizeUsageManagment />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_USAGE_SUMMARY}
                element={
                  <PrivateRoute>
                    <OptimizationUsageSummary />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_ACTION}
                element={
                  <PrivateRoute>
                    <OptimizeAction />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_ACTION_SUMMARY}
                element={
                  <PrivateRoute>
                    <OptimizeActionSumary />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.COST_ANOMOLY}
                element={
                  <PrivateRoute>
                    <CostAnomoly />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.BUDGET_MANAGMENT}
                element={
                  <PrivateRoute>
                    <BudgetManagment />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.MARKUP_AND_DISCOUNTS}
                element={
                  <PrivateRoute>
                    <MarkupAndDiscount />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_USAGE}
                element={
                  <PrivateRoute>
                    <OptimizeUsage />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_RATE}
                element={
                  <PrivateRoute>
                    <OptimizeRate />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.ANOMOLY_DASHBOARD}
                element={
                  <PrivateRoute>
                    <AnamolyDashboard />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.ALERTS_AND_INSIGHTS}
                element={
                  <PrivateRoute>
                    <BudgetAlertsAndInsights />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_USAGE_MANAGMENT}
                element={
                  <PrivateRoute>
                    <OptimizeUsageManagment />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.OPTIMIZE_USAGE_SUMMARY}
                element={
                  <PrivateRoute>
                    <OptimizationUsageSummary />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.COST_ANOMOLY_DASHBOARD}
                element={
                  <PrivateRoute>
                    <CostAnomolyDashboard />
                  </PrivateRoute>
                }
              ></Route>

              <Route
                path={ROUTE_PATH.BILLING.BUDGET_MANAGMENT}
                element={
                  <PrivateRoute>
                    <BudgetManagment />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.CREATE_BUDGET}
                element={
                  <PrivateRoute>
                    <CreateBudget />
                  </PrivateRoute>
                }
              ></Route>

              {/* CLM Route */}
              <Route
                path={ROUTE_PATH.BILLING.COST_OVERVIEW.ACCOUNT_BILLING}
                element={
                  <PrivateRoute>
                    <ClmBilling />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.COST_OVERVIEW.RESOURCE_BILLING}
                element={
                  <PrivateRoute>
                    <ClmResourceBilling />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.COST_OVERVIEW.PROJECT_BILLING}
                element={
                  <PrivateRoute>
                    <ClmProjectBilling />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path={ROUTE_PATH.BILLING.COST_OVERVIEW.APPLICATION_BILLING}
                element={
                  <PrivateRoute>
                    <ClmApplicationBilling />
                  </PrivateRoute>
                }
              ></Route>

              {/* User Route */}
              <Route
                path={ROUTE_PATH.user.userView}
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              ></Route>

              {/* Default Redirect to Login for Unknown Routes */}
              <Route
                path="*"
                element={<Navigate to={ROUTE_PATH.LOGIN} replace />}
              />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default RoutesWrapper;
