/* eslint-disable react-hooks/rules-of-hooks */
import { useReducer } from "react";
import React, { useEffect, useState } from "react";

import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FaPlay, FaStop } from "react-icons/fa";

import axios from "../../../../api/axios";
import moreIcon from "../../../../Images/moreIcon.svg";
import infoIcon from "../../../../Images/infoIcon.svg";
import refreshIcon from "../../../../Images/refresh.svg";
import rebootIcon from "../../../../Images/restartIcon.svg";
import filterIcon from "../../../../Images/filterFilled.svg";
import moreIconDisabled from "../../../../Images/moreIconDisabled.svg";
import infoIconDisabled from "../../../../Images/infoIconDisabled.svg";
import rebootIconDisabled from "../../../../Images/restartIconDisabled.svg";

import "../../../../style/Infra/workSpace.scss";
import TableColumnSearch from "../../../../components/common/TableSearchInput/tableSearch";
import {
  commonConstants,
  localStorageHeaders,
  toastIds,
} from "../../../../constants/constant";
import { API_URLS } from "../../../../constants/API_URLS";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import CommonTable from "../../../../components/Infra/Table/table";
import { ToastError, ToastSuccess } from "../../../../utils/utils";
import OperationModal from "../../../../components/Infra/OperationModal";
import ManageTags from "../../../../components/InfraAccordion/manageTags";

const initialState = {
  showMoreOptions: false,
  isAdded: false,
  tableData: [],
  tableDataRaw: [],
  openManageTagsModal: false,
  loading: false,
  selectedRowsTable: [],
  openStartModal: false,
  openRemoveTags: false,
  openStopModal: false,
  openRebootModal: false,
  tagValue: {},
  openRemoveWorkSpaceModal: false,
  openManageTagSearchModal: false,
  selectedRows: [],
  currentPage: 1,
  filterCheckboxDisabled: false,
  resetPagination: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function WorkSpace() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };
  const navigate = useNavigate();

  const columns = [
    {
      name: "WorkSpace ID",
      selector: (row) => row.workspaceId,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "UserName",
      selector: (row) => row.userName,
    },
    {
      name: "Compute",
      selector: (row) => row.compute,
    },
    {
      name: "Root Volume",
      selector: (row) => row.rootVolume,
    },
    {
      name: "User Volume",
      selector: (row) => row.userVolume,
    },
    {
      name: "Running Mode",
      selector: (row) => row.runningMode,
    },
    {
      name: "Account",
      selector: (row) => row.account,
    },
  ];
  const data = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = state.tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setData({ tableData: mergedData });
    } else {
      const result = state.tableDataRaw.map((data, index) => {
        let newItem = {
          workspaceId: (
            <p
              className="workspace-container_workspaceIDselectable"
              onClick={() => {
                localStorage.setItem(
                  localStorageHeaders.selectedRowWorkspace,
                  JSON.stringify(data)
                );
                navigate("/compute/workspace/details");
              }}
            >
              {data?.workspaceId}
            </p>
          ),
          status: data?.status,
          userName: data?.userName,
          compute: data?.compute,
          rootVolume: data?.rootVolume,
          userVolume: data?.userVolume,
          runningMode: data?.runningMode,
          account: data?.account,
          id: index,
        };
        return newItem;
      });
      let mergedData = [SearchObject, ...result];
      setData({ tableData: mergedData });
    }
  };

  const SearchObject = {
    workspaceId: (
      <TableColumnSearch
        column={"workspaceId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    status: (
      <TableColumnSearch
        column={"status"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    userName: (
      <TableColumnSearch
        column={"userName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    compute: (
      <TableColumnSearch
        column={"compute"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    rootVolume: (
      <TableColumnSearch
        column={"rootVolume"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    userVolume: (
      <TableColumnSearch
        column={"userVolume"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    runningMode: (
      <TableColumnSearch
        column={"runningMode"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    account: (
      <TableColumnSearch
        column={"account"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };
  const OnFilterClick = () => {
    if (state.currentPage == 1) {
      const onlyObjectsWithStrings = state.tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (state.isAdded) {
        setData({
          tableData: onlyObjectsWithStrings,
          filterCheckboxDisabled: false,
        });
      } else {
        setData({
          tableData: [SearchObject, ...onlyObjectsWithStrings],
          filterCheckboxDisabled: true,
        });
      }
    } else {
      if (state.isAdded) {
        setData({ filterCheckboxDisabled: false });
      } else {
        setData({
          tableData: [SearchObject, ...state.tableData],
          filterCheckboxDisabled: true,
        });
      }
    }
    setData({ isAdded: !state.isAdded, resetPagination: true });
  };

  const getnotebookData = () => {
    setData({ loading: true });
    axios
      .get(API_URLS.infra.compute.workspace.workspaceList)
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          setData({ tableDataRaw: result?.data });

          let tableData = result?.data?.map((data, index) => {
            let temp = {
              workspaceId: (
                <p
                  className="workspace-container_workspaceIDselectable"
                  onClick={() => {
                    localStorage.setItem(
                      localStorageHeaders.selectedRowWorkspace,
                      JSON.stringify(data)
                    );
                    navigate("/compute/workspace/details");
                  }}
                >
                  {data?.workspaceId}
                </p>
              ),
              status: data?.status,
              userName: data?.userName,
              compute: data?.compute,
              rootVolume: data?.rootVolume,
              userVolume: data?.userVolume,
              runningMode: data?.runningMode,
              account: data?.account,
              id: index,
            };
            return temp;
          });
          setData({ tableData: tableData });
        } else {
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };

  const onManageTags = (tags) => {
    const tagBody = state.selectedRows?.map((data) => {
      return {
        accountId: data?.accountId,
        operation: commonConstants.tags,
        workspaceId: data?.workspaceId,
        tags: tags,
      };
    });
    OnWorkSpaceOperation(tagBody);
  };

  useEffect(() => {
    getnotebookData();
  }, []);
  useEffect(() => {
    const selectedNotebookIds = state.selectedRowsTable?.selectedRows?.map(
      (data) => {
        return data?.id;
      }
    );
    const selectedRowRaw = state.tableDataRaw?.filter((data, index) =>
      selectedNotebookIds.includes(index)
    );
    setData({ selectedRows: selectedRowRaw });
  }, [state.selectedRowsTable]);

  const OnWorkSpaceOperation = (data) => {
    axios
      .post(API_URLS.infra?.compute?.workspace?.workspaceOperation, data)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          ToastSuccess(response?.data?.statusMessage, toastIds.successToastId);
          getnotebookData();
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      );
  };

  return (
    <>
      <OperationModal
        show={state.openStartModal}
        heading={"Start WorkSpace"}
        subHeading={"Are you sure you want to start the below WorkSpace?"}
        type={"Workspace"}
        operation={"start"}
        onHide={() => setData({ openStartModal: false })}
        selectedRows={state.selectedRows}
        onStart={(data) => OnWorkSpaceOperation(data)}
      />
      <OperationModal
        show={state.openRebootModal}
        heading={"Reboot WorkSpace"}
        subHeading={"Are you sure you want to reboot the below WorkSpace?"}
        type={"Workspace"}
        operation={"reboot"}
        onHide={() => setData({ openRebootModal: false })}
        selectedRows={state.selectedRows}
        onReboot={(data) => OnWorkSpaceOperation(data)}
      />
      <OperationModal
        show={state.openStopModal}
        heading={"Stop WorkSpace"}
        subHeading={"Are you sure you want to stop the below WorkSpace?"}
        type={"Workspace"}
        operation={"stop"}
        onHide={() => setData({ openStopModal: false })}
        selectedRows={state.selectedRows}
        onStop={(data) => OnWorkSpaceOperation(data)}
      />
      <OperationModal
        show={state.openRemoveWorkSpaceModal}
        heading={"Remove WorkSpace"}
        subHeading={"Are you sure you want to remove the below WorkSpace?"}
        type={"Workspace"}
        operation={"remove"}
        onHide={() => setData({ openRemoveWorkSpaceModal: false })}
        selectedRows={state.selectedRows}
        onRemove={(data) => OnWorkSpaceOperation(data)}
      />
      <OperationModal
        show={state.openRemoveTags}
        heading={"Remove Tags"}
        subHeading={"Are you sure you want to remove the tags?"}
        type={"Workspace"}
        operation={"removeTags"}
        onHide={() => setData({ openRemoveTags: false })}
        selectedRows={state.selectedRows}
        onRemove={(data) => OnWorkSpaceOperation(data)}
      />
      {/* <OperationModal
        show={state.openRemoveWorkSpaceModal}
        heading={"Remove Tags"}
        subHeading={"Are you sure you want to remove the tags?"}
        type={"Workspace"}
        operation={"removeTags"}
        onHide={() => setData({ openRemoveWorkspaceModal: false })}
        selectedRows={state.selectedRows}
        onRemove={(data) => OnWorkSpaceOperation(data)}
      /> */}
      <ManageTags
        show={state.openManageTagsModal}
        onHide={() => setData({ openManageTagsModal: false })}
        getKeyValPairs={(data) => {
          let tags = {};
          for (const item in data) {
            tags[data[item]?.key] = data[item]?.value;
          }
          onManageTags(tags);
        }}
      />

      <div className="workspace-container mx-4  mt-5">
        <h5 className=" text-start ms-1">AWS WorkSpaces</h5>
        <div className=" border roundedshadow border border-0 mt-3  mx-2">
          {/* <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap">
              {state.selectedRowsTable?.selectedCount > 0 ? (
                <>
                  <TableButton
                    icon={
                      <FaPlay
                        className="manage-icon"
                        onClick={() =>
                          setData({ openStartModal: !state.openStartModal })
                        }
                        color="black"
                      ></FaPlay>
                    }
                    title={"Start"}
                  />
                  <TableButton
                    icon={
                      <FaStop
                        className="manage-icon"
                        onClick={() =>
                          setData({ openStopModal: !state.openStopModal })
                        }
                        color="black"
                      ></FaStop>
                    }
                    title={"Stop"}
                  />
                </>
              ) : (
                <>
                  <TableButton
                    icon={<FaPlay className="manage-icon"></FaPlay>}
                    title={"Start"}
                  />
                  <TableButton
                    icon={<FaStop className="manage-icon"></FaStop>}
                    title={"Stop"}
                  />
                </>
              )}
              {state.selectedRowsTable?.selectedCount === 1 &&
              state.selectedRows[0]?.status != "STOPPED" ? (
                <TableButton
                  icon={
                    <img
                      className="workspace-container_tableIcons"
                      src={rebootIcon}
                      alt="rebootIcon"
                      onClick={() =>
                        setData({ openRebootModal: !state.openRebootModal })
                      }
                    ></img>
                  }
                  title={"Reboot"}
                />
              ) : (
                <TableButton
                  icon={
                    <img
                      className="workspace-container_tableIcons"
                      src={rebootIconDisabled}
                      alt="rebootIcon"
                    ></img>
                  }
                  title={"Reboot"}
                />
              )}
              {state.selectedRowsTable?.selectedCount === 1 ? (
                <TableButton
                  icon={
                    <img
                      className="workspace-container_tableIcons"
                      src={infoIcon}
                      onClick={() => {
                        localStorage.setItem(
                          localStorageHeaders.selectedRowWorkspace,
                          JSON.stringify(state.selectedRows[0])
                        );
                        navigate("/compute/workspace/details");
                      }}
                      alt="infoIcon"
                    ></img>
                  }
                  title={"View Details"}
                />
              ) : (
                <TableButton
                  icon={
                    <img
                      className="workspace-container_tableIcons"
                      src={infoIconDisabled}
                      alt="infoIcon"
                    ></img>
                  }
                  title={"View Details"}
                />
              )}

              <div className="workspace-container_tableButtons">
                {state.showMoreOptions && (
                  <div className="workspace-container_listItems">
                    <ListGroup defaultActiveKey="#link1">
                      <ListGroup.Item
                        action
                        onClick={() => {
                          setData({
                            openRemoveWorkSpaceModal:
                              !state.openRemoveWorkSpaceModal,
                            showMoreOptions: false,
                          });
                        }}
                      >
                        Remove WorkSpace
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        onClick={() => {
                          setData({
                            openManageTagsModal: !state.openManageTagsModal,
                            showMoreOptions: false,
                          });
                        }}
                      >
                        Manage Tags
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                )}
                {state.selectedRowsTable?.selectedCount > 0 ? (
                  <TableButton
                    icon={
                      <>
                        <img
                          className="workspace-container_tableIcons"
                          src={moreIcon}
                          alt="moreIcon"
                          onClick={() =>
                            setData({
                              showMoreOptions: true,
                            })
                          }
                        ></img>
                      </>
                    }
                    title={"More"}
                  />
                ) : (
                  <TableButton
                    icon={
                      <>
                        <img
                          className="workspace-container_tableIcons"
                          src={moreIconDisabled}
                          alt="moreIcon"
                        ></img>
                      </>
                    }
                    title={"More"}
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap">
              {state.tableData.length > 0 && (
                    <TableButton
                      icon={
                        <img
                          className="workspace-container_tableIcons"
                          src={filterIcon}
                          alt="shareIcon"
                          onClick={() => OnFilterClick()}
                        ></img>
                      }
                      title={"Filter"}
                    />
                  )}
              <TableButton
                icon={
                  <img
                    className="workspace-container_tableIcons"
                    src={refreshIcon}
                    alt="shareIcon"
                    onClick={() => getnotebookData()}
                  ></img>
                }
                title={"Refresh"}
              />
            </div>
          </div> */}

          <CommonTable
            removeSearchCheckbox={state.filterCheckboxDisabled}
            onChangePage={(data) => {
              setData({ resetPagination: false, currentPage: data });
            }}
            resetPagination={state.resetPagination}
            columns={columns}
            onSelectedRowsChange={(data) =>
              setData({ selectedRowsTable: data })
            }
            data={state.tableData}
            selectableRows={true}
            loading={state.loading}
          />
        </div>
      </div>
    </>
  );
}
