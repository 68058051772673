import axios from "axios";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import store from "../redux/Store";
import { SET_LOGIN_RESPONSE } from "../redux/Slices/AuthSlice";
import { LocalStorageManager } from "../utils/LocalStorageManager";
import { ErrorMessage, toastIds } from "../constants/constant";
import { API_URLS } from "../constants/API_URLS";
import useReduxDispatch from "../utils/utils";

const mode = (type) => {
  switch (type) {
    case "development":
      return "https://api-bvd.acc.ltd"; // development URL
    case "preprod":
      return "https://api-bvd.acc.ltd"; // pre prod URL
    case "production":
      return "https://api-bvd.acc.ltd"; // production URL
    default:
      return "https://api-bvd.acc.ltd";
  }
};

export const defaultOptions = {
  baseURL: mode(process.env.REACT_APP_CUSTOM_ENV),
  method: "get",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YnZkYTphY2NAYnZkYQ==",
    "ngrok-skip-browser-warning": "any value",
  },
};

let instance = axios.create(defaultOptions);
instance.interceptors.request.use(
  function (config) {
    const token = LocalStorageManager.getToken() || undefined;
    if (token) {
      config.headers["x-access-token"] = token;
      config.headers["ngrok-skip-browser-warning"] = true;
    } else {
      delete config.headers["x-access-token"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      !error?.request?.responseURL.includes(API_URLS?.LOGIN?.url)
    ) {
      store.dispatch(SET_LOGIN_RESPONSE({}));
      // window.location.replace('/login');
    }
    if (error) {
      return Promise.reject(error);
    }
  }
);

export const getToken = () => {
  const token = LocalStorageManager.getToken() || undefined;
  if (token) {
    try {
      const JWT = jwt_decode(token);
      const currentDate = Math.round(new Date().getTime() / 1000);
      if (JWT && JWT.exp > currentDate) {
        return token;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
  return null;
};

export default instance;
