import React, { useEffect, useState } from "react";

import refreshIcon from "../../../../Images/refresh.svg";
import axios from "../../../../api/axios";
import CommonTable from "../../../../components/Infra/Table/table";
import CommonTooltip from "../../../../components/Tooltip";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import { API_URLS } from "../../../../constants/API_URLS";
import { toastIds } from "../../../../constants/constant";
import { ToastError } from "../../../../utils/utils";

import "../../../../style/Infra/s3.scss";
export default function UnusedResourceS3() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterCheckboxDisabled, setFilterCheckboxDisabled] = useState(true);
  const [resetPagination, setResetPagination] = useState(false);
  const columns = [
    {
      name: "Bucket Name",
      selector: (row) => CommonTooltip(row.bucketName),
    },
    {
      name: "Region",
      selector: (row) => row.region,
    },
    {
      name: "Size In Bytes",
      selector: (row) => row.sizeInBytes,
    },
    {
      name: "Account Id",
      selector: (row) => row.accountId,
    },
    {
      name: "Account Name",
      selector: (row) => row.accountName,
    },

    {
      name: "Is Active",
      selector: (row) => row.active,
    },
  ];

  const getTableData = () => {
    setLoading(true);
    axios
      .get(API_URLS.infra.unusedResource.s3.getUnusedResourcesS3)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTableDataRaw(response?.data?.data[0]);

          setTableData([response?.data?.data[0]]);
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className=" commmon_stye  mt-3">
      <h5 className=" text-start ms-1">S3</h5>

      <div className="container_style">
        <div className="d-flex justify-content-end">
          <div className="d-flex flex-wrap">
            <TableButton
              icon={
                <>
                  <img
                    className="s3_tableIcons"
                    src={refreshIcon}
                    alt="moreIcon"
                    onClick={() => getTableData()}
                  ></img>
                </>
              }
              title={"Refresh"}
            />
          </div>
        </div>

        <CommonTable
          removeSearchCheckbox={filterCheckboxDisabled}
          onChangePage={(data) => {
            setResetPagination(false);
            setCurrentPage(data);
          }}
          resetPagination={resetPagination}
          columns={columns}
          data={tableData || []}
          selectableRows={true}
        />
      </div>
    </div>
  );
}
